.upload-image-container{
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  margin-top: 5px;
  margin-top: 15px;
}
.upload-image-container .image-container{
  width:100px;
  height: 100px;
  position: relative;
  /* width:100px; */
  /* height: 100px; */
}
.upload-image-container .upload-image{
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.upload-image-container label{
  width: 100px;
}
.image-delete{
  position: absolute;
  right: -6px;
  top: -25px;
  z-index: 100;
  font-weight: bolder;
  height: 30px;
  width: 30px;
  font-size: 20px;
  /* background-color: white; */
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  /* border: 3px solid red; */
}
.image-delete-video{
  right: 12px;
  top: -32px;
  font-size: 25px;
}
.video-input{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.VideoInput{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.video-upload-section{
  padding: 0;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}
/* 20220914 adding image from api to ingredients list */
.modal-ing-img{
  height: 50px;
  width: auto;
  transition: transform .3s ease;
}
.modal-ing-img:hover{
  transform: scale(1.30);
}
.ingredient-name{
  margin: 0;
  flex: 1;
  /* flex: 1 !important; */
}
[data-icon="square-check"] ~ .modal-ing-img{
  transform: scale(1.20);
}
.ingredient-search-field{
  margin-top: 50px;
  border-radius: 20px;
  border-color: #85B492;
  border: solid;
  outline: none;
}
#ingredient-search{
  padding: 5px 10px;
}
.ingredient-name-in-list{
  flex: 1;
  font-family: poppins;
  color: #707070;
}
.ingredient-name-in-list-1{
  font-family: poppins;
color: #707070;
width: 44%;
max-width: 47%;
}
button.slick-arrow{
  display: block;
    background: #85B492;
    color: yellow;
    border-radius: 50%;
}
.follow-link{
color: #85B492;
font-weight: bold;
cursor: pointer;
}
.dot {
  height: 110px;
  width: 110px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #85b492; 
}
.energy-btn{
  position: relative;
  top: 25px;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}
.calc-time{
  padding: 15px;
  
/* box-shadow: 2px 5px 10px -6px rgba(0,0,0,0.75); */
box-shadow: 5px 10px 20px -10px rgb(0 0 0 / 50%);
font-size: 18px;
font-weight: 600;
border-radius: 10px;
}
.like-heart-icon{
  position: relative;
  top: 191px;
  cursor: pointer;
}
.recipe-heading-name{
  font-size: 18px;
font-weight: bold
}
.nos-color{
  color: rgb(133, 180, 146);
}
.auther-by{
  color: #85B492;
font-size: 18px;
font-weight: 500;
}
.circle-with-nos{
 width: 91%;
position: relative;
right: -3px;
}
.circle-with-nos-en{
  width: 91%;
}
.circle-with-fat-no{
  width: 72%;
}
@media (max-width: 575.98px) {
  .energy-btn {
    position: relative;
    top: 16px;
    font-size: 12px;
    text-align: center;
  }
  .dot {
    height: 68px;
    width: 68px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    border: 3px solid #85b492; 
  }
  .calc-time {
    font-size: 14px;
    font-weight: 600;
  }
  .circle-with-nos {
    width: 100%;
    position: relative;
    top: -3px;
    right: 1px;
  }
    
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .circle-with-nos {
      width: 91%;
      top: -2px;
      position: relative;
      right: -2px;
    }
  }
