/**Poppin fonts**/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  background: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #707070;
}

/**Hover css**/
a {
  color: #707070;
  text-decoration: none;
}

a:hover {
  transition: 0.5s;
  color: #85b492;
  text-decoration: none;
}

:focus-visible {
  outline: none !important;
}

/* li {
  list-style: none;
} */

/**Heading cs**/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #000;
}

h1 {
  font-size: 34px;
  margin: 0 0 30px 0;
  line-height: 45px;
  font-weight: 600;
}

h2 {
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
}

h3 {
  font-size: 30px;
  line-height: 30px;
  margin: 0 0 15px 0;
  font-weight: 600;
}

h4 {
  font-size: 18px;
  line-height: 26px;
  margin: 0 0 15px 0;
  font-weight: 500;
}

p {
  margin: 0 0 20px 0;
  /* font-size: 14px; */
  font-size: calc(1.1rem + 0.3vw) !important;
}

.nopadding {
  padding: 0 !important;
}

.padding5 {
  padding: 0 5px !important;
}

.padding1 {
  padding: 0 10px !important;
}

/**Listing css**/
ul {
  width: 100%;
  margin: 0;
  padding: 0;
}

.com-ul {
  display: block;
  width: 100%;

}

.com-ul li {
  width: 100%;
  display: block;
  padding: 0 0 12px 15px;
  position: relative;
  font-size: 1.5rem;
  font-weight: 600;
  color: #707070;
}

.com-ul li:before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 7px;
  left: 0;
}

/**Input css**/
.form-group {
  margin: 0 0 40px 0;
  display: block;
}

.input-effect {
  display: block;
  width: 100%;
  position: relative;
}

#message,
input[type="text"],
input[type="number"],
select {
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  padding: 5px 0;
  color: #85b492;
}

.effect {
  width: 100%;
  border: 0;
  padding: 4px 0;
  border-bottom: 1px solid #85b492;
  background-color: transparent;
}

.chipeffect {
  border: none !important;
}

.chipanother {
  box-shadow: 0 0 0 0.2rem transparent;
  border: 1px solid transparent;
  transition: border 0.15s linear, box-shadow 0.15s linear;
}

.effect~.focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #85b492;
  transition: 0.4s;
}

.effect:focus~.focus-border,
.has-content.effect~.focus-border {
  width: 100%;
  transition: 0.4s;
}

.effect~label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 5px;
  color: #85b492;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
  text-align: left;
}

.effect:focus~label,
.has-content.effect~label {
  top: -20px;
  font-size: 16px;
  line-height: 30px;
  transition: 0.3s;
}

.contact-form .dropdown-select {
  background: none;
  box-shadow: none;
  width: 100%;
  border: 0;
  border-radius: 0;
  padding: 10px 0;
  border-bottom: 1px solid #85b492;
  background-color: transparent;
  color: #85b492;
}

.contact-form .dropdown-select-ul {
  top: 40px;
}

textarea {
  height: 80px;
  width: 100%;
  display: block;
  margin: 0 auto 40px;
}

.error {
  width: 100%;
  color: #f90004;
  font-size: 12px;
  display: none;
  position: absolute;
  bottom: -20px;
  left: 0;
}

:focus {
  box-shadow: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.desktop-display {
  display: block;
}

.device-display {
  display: none;
}

/**Button css**/
.btn {
  background: #85b492;
  border: 2px solid #85b492;
  border-radius: 10px;
  width: auto;
  min-width: 114px;
  padding: 10px 15px;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
}


.btn:hover {
  background: #fff;
  border: 2px solid #85b492;
  color: #85b492
}

.btn1 {
  background: #85b492;
  border: 2px solid #85b492;
  border-radius: 10px;
  width: auto;
  min-width: 200px;
  padding: 10px 15px;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px;
  text-align: center;
}

.btn1:hover {
  background: #fff;
  border: 2px solid #85b492;
  color: #85b492 !important;
  text-align: center;
}

.container-fluid {
  max-width: 1400px;
}

/**Banner Section CSS**/
.banner-section {
  width: 100%;
  display: block;
  padding: 0;
  margin: 100px auto 200px;
  background: url("../images/banner-bg.png") no-repeat top left;
  position: relative;
  background-size: contain;
}

.banner-content {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.banner-content .title {
  line-height: 50px;
  margin: 200px 0 20px 0;
}

.ul-btn {
  width: 100%;
  display: block;
}

.ul-btn li {
  width: auto;
  display: inline-block;
  margin: 0 15px 0 0;
  color: #000;
}

.ul-btn li.tutorial {
  padding: 10px 0;
  margin: 0;
}

.ul-btn li i {
  font-size: 25px;
  display: inline-block;
  color: #85b492;
}

.ul-btn li span {
  display: inline-block;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  line-height: 16px;
  margin: 0 0 0 10px;
  text-transform: uppercase;
  color: #000000;
}

.ul-btn li span strong {
  display: block;
  width: 100%;
  font-weight: 600;
  text-transform: initial;
  font-family: poppins;
}

.ul-btn li b {
  width: 40px;
  height: 40px;
  background: #85b492;
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
}

.ul-btn li b i {
  color: #fff;
  font-size: 14px;
  margin: 0 0 0 5px;
}

.ul-btn li.btn {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
}

.banner-slider {
  width: 40%;
  max-width: 550px;
  height: 100%;
  text-align: center;
  position: absolute;
  right: 50px;
  top: 0;
  z-index: 10;
}

.banner-slider .dish__item .dish__slider:before {
  content: "";
  max-width: 800px;
  width: 95%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url("../images/circle-bg.png") no-repeat center bottom;
  background-size: 100%;
}

.banner-slider .slick-next {
  right: 0 !important;
}

.banner-slider .slick-prev {
  left: 0 !important;
}

.banner-slider .slick-prev,
.banner-slider .slick-next {
  width: 40px;
  height: 40px;
  z-index: 999;
  top: 60px !important;
  background: none;
}

.banner-slider .slick-prev:hover,
.banner-slider .slick-next:hover {
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.banner-slider .slick-prev.slick-disabled,
.banner-slider .slick-next.slick-disabled {
  opacity: 0;
  visibility: hidden;
}

.banner-slider .slick-next::before,
.banner-slider .slick-prev::before {
  content: "";
  border: solid #b4b4b4;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 10px;
  font-family: inherit;
  border-radius: 2px;
}

.banner-slider .slick-next::before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.banner-slider .slick-prev::before {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.banner-slider .dish__nav .item {
  cursor: pointer;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.banner-slider .dish__nav {
  overflow: hidden;
  text-align: center;
  margin: -100px auto 0;
  padding: 0 25px;
}

.banner-slider .dish__nav .slick-slide img {
  margin: 0 auto;
  max-width: 100%;
}

.banner-slider .dish__nav .slick-list {
  margin: 0 auto;
}

.banner-slider .dish__nav .slick-current.slick-active {
  outline: none !important;
}

.banner-slider .dish__slider .item img {
  width: 100%;
  margin: 0 auto;
  display: block;
}

.banner-slider .dish__nav .item:hover img,
.banner-slider .dish__nav .slick-current.slick-active .item img {
  border: 10px solid rgb(255, 255, 255, 0.9);
  border-radius: 50%;
}

.banner-slider .dish__nav .item img,
.banner-slider .dish__nav .slick-current.slick-active .item img {
  border: 10px solid rgb(255, 255, 255, 0);
  border-radius: 50%;
}

.banner-slider .dish__item {
  position: relative;
}

img {
  width: 100%;
}

.like-spe {
  width: 160px;
  height: 45%;
  position: absolute;
  left: 90px;
  top: 30px;
  bottom: 0;
  margin: auto;
  z-index: 9;
}

.green-circle {
  width: 65px;
  height: 65px;
  display: block;
  border-radius: 50%;
  margin: 0 auto;
  position: absolute;
  background: #85b492;
  overflow: hidden;
}

.green-circle:hover {

  overflow: inherit;
}

.speak-icon {
  background: url("../images/speak-icon.png") no-repeat;
  background-size: 100%;
}

.like-icon {
  background: url("../images/like-icon.png") no-repeat;
  background-size: 100%;
  bottom: 0;
}

.like-spe .cook-dish {
  width: auto;
  height: auto;
  position: absolute;
  line-height: 30px;
  top: 125px;
  left: -60px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: block;
  font-size: 14px;
  color: #85b492;
}

.like-spe .cook-dish i {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
  float: left;
  background: url("../images/spirit-icon.png") no-repeat -1px -3px;
}

.tooltip {
  position: absolute;
  width: auto;
  height: auto;
  min-width: 210px;
  padding: 15px 5px;
  display: block;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  z-index: 99;
  text-align: center;
  left: 120%;
  top: 15px;
  transition: 0.5s;
}

.tooltip strong {
  color: #85b492;
  margin: 0 5px 0 0;
}

.tooltip h3 {
  font-size: 14px;
  color: #85b492;
  font-weight: 600;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.tooltip strong.red-txt {
  color: #fe043c;
}

.green-circle:hover .tooltip {
  opacity: 1 !important;
}

.green-circle .tooltip.left {
  left: -330%;
}

.green-circle:hover .tooltip {
  opacity: 1 !important;
}

.tooltip.listtip {
  width: 240px;
  padding: 15px;
  display: block;
  text-align: left;
}

.ul-tooltip {
  width: 100%;
  display: block;
  border-top: 1px solid #f1f1f1;
  margin: 15px auto 0;
  padding: 15px 0 0;
}

.ul-tooltip p {
  color: #85b492;
  font-size: 12px;
  margin: 0;
}

.ul-tooltip li {
  margin: 0 0 10px 0;
  width: 100%;
  display: block;
}

.ul-tooltip li strong {
  font-weight: 600;
  display: inline-block;
  width: auto;
  float: right;
  color: #000;
}

.tooltip.listtip .add-icon {
  width: 40px;
  height: 40px;
  position: inherit;
  float: right;
  /* background: url("file:///E:/flavourable/HTML/images/plus-icon.png")no-repeat 0 0; */
  background: url("../images/plus-icon.png") no-repeat 0 0;
  background-size: 100%;
}

.tooltip.shopping {
  display: block;
}

.shopping .ul-tooltip li {
  padding: 0 0 0 15px;
  position: relative;
  color: #707070;
}

.shopping .ul-tooltip li:before {
  content: "";
  width: 5px;
  height: 5px;
  background: #707070;
  border-radius: 5px;
  display: block;
  top: 9px;
  left: 0;
  position: absolute;
}

.shopping .ul-tooltip strong {
  font-weight: 400;
}

.shopping .ul-tooltip h3 {
  color: #000;
  margin: 0 0 10px 0;
}

/**Header CSS**/
header {
  padding: 15px 0;
  position: fixed;
  width: 100%;
  display: block;
  top: 0;
  margin: 0 auto;
  z-index: 30;
  background: linear-gradient(180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.3) 100%);
}

header.sticky {
  padding: 10px 0;
  transition: 0.5s;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
}

header.sticky .logo {
  max-width: 250px;
  transition: 0.5s;
}

header.sticky .nav-links {
  margin: 10px 0 0 0;
}

.logo {
  width: auto;
  max-width: 275px;
  display: inline-block;
  z-index: 1;
}

.logo img {
  width: 80%;
  display: block;
}

/**Menu CSS**/
nav {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  z-index: 10;
}

.nav-links {
  display: flex;
  list-style: none;
  width: auto;
  padding: 0;
  justify-content: space-evenly;
  align-items: center;
  float: right;
  margin: 20px 34px 0 0;
}

.nav-links li a {
  text-decoration: none;
  margin: 0 0.7vw;
  font-size: 13px;
  font-weight: 600;
  color: #000;
  font-family: poppins;
  
}

.nav-links li a.btn {
  color: #fff;
}

.nav-links li a:hover {
  color: #85b492;
}

.nav-links li {
  position: relative;
  margin: 0 0 0 15px;
}

.nav-links li a::after {
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 5px;
  background-color: #85b492;
  position: absolute;
  transition: all ease-in-out 0.5ms;
  margin: 0 0 0 10%;
  top: 0;
  right: 0;
  display: none;
}

.nav-links li a.active {
  color: #85b492;
}

.nav-links li a:hover:after,
.nav-links li a.active:after {
  display: block;
}

/*Hamburger Icon*/
.hamburger div {
  width: 40px;
  height: 3px;
  background: #85b492;
  margin: 3px 0;
  transition: all 0.3s ease;
}

.hamburger {
  display: none;
}

.title {
  width: 100%;
  font-size: 40px;
  line-height: 42px;
  font-weight: 700;
  margin: 0 0 20px 0;
  padding: 0;
}

.title span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #85b492;
  display: block;
  margin: 0 0 15px 0;
}

/**Live Stream Section CSS**/
.live-stream-section {
  width: 100%;
  margin: 0 auto;
  padding: 60px 0 150px;
  position: relative;
  background: #f8fafb;
  min-height: 800px;
}

.live-stream-section .title {
  margin: 120px 0 20px 0;
}

.live-device-area {
  width: 45%;
  height: 100%;
  min-height: 1110px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.live-device-area:before {
  content: "";
  width: 100%;
  max-width: 630px;
  height: 100%;
  background: url("../images/plant.png") no-repeat 0 0;
  background-size: contain;
  position: absolute;
  top: 0;
  left: -40px;
  z-index: 0;
}

.live-device-area .live-device-box {
  width: 640px;
  height: 100%;
  z-index: 9;
  position: relative;
  margin: 60px 0 0 0;
  /*background: url("../images/mobile-shodow-bg.png")no-repeat top left*/
}

.live-device-screen {
  width: 380px;
  height: auto;
  position: absolute;
  right: 72px;
  top: 110px;
  border-radius: 20px;
  display: block;
  padding: 15px;
}

.live-device-screen:before {
  content: "";
  position: absolute;
  width: 450px;
  height: 100%;
  min-height: 900px;
  display: block;
  background: url("../images/mobile.png") no-repeat top right;
  background-size: 100%;
  right: -18px;
  top: -40px;
  -webkit-box-shadow: -28px 24px 50px 13px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: -28px 24px 50px 13px rgba(255, 255, 255, 0.2);
  box-shadow: -28px 24px 50px 13px rgba(255, 255, 255, 0.2);
}

.live-device-screen .screen-video {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.live-device-screen .screen-video img {
  width: 100%;
  margin: 0 auto;
  /* display: block; */

}

.live-device-screen .screen-video a {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;
  font-size: 50px;
}

.live-device-screen .screen-content {
  width: 100%;
  height: 100%;
  display: inline-block;
  margin: 20px auto;
  position: relative;
}

.live-device-screen .screen-content .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 15px 0;
}

.live-device-screen .screen-content .title span {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}

.upload-icon {
  width: 25px;
  height: 25px;
  display: inline-block;
  float: right;
  background: url("../images/spirit-icon.png") no-repeat -3px -282px;
}

.screen-content .com-ul li {
  width: 100%;
  display: block;
  padding: 0 0 10px 10px;
  position: relative;
  font-weight: 600;
}

.com-ul li:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  top: 7px;
  left: 0;
}

.scr-ply-btn {
  line-height: 80px;
  position: absolute;
  top: 45%;
  right: -55px;
  bottom: 0;
  text-align: center;
  background: url("../images/play-icon.png") no-repeat 0 0;
  background-size: 100%;
}

.live-device-screen .speak-icon {
  right: -150px;
  top: 50px;
  bottom: inherit;
}

.notification {
  width: 20px;
  height: 20px;
  background: #fe043c;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  right: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  line-height: 20px;
}

/**Allergy Alert & Recipes Section CSS**/
.allergy-section {
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 350px 0 80px;
  background: url("../images/allergy-bg.png") no-repeat top right;
  position: relative;
  overflow: hidden;
  background-size: contain;
}

.recipes-slider-area {
  width: 45%;
  float: right;
  height: 100%;
  position: absolute;
  right: 0;
  top: 200px;
}

.recipes-slider .slick-slide {
  width: 100%;
  display: block;
  margin: 100px 15px !important;
}

.recipes-slider .slick-slide img {
  width: 100%;
  display: block;
}

.recipes-slider .slick-slide.slick-active {
  width: 300px !important;
  display: block;
}

.recipes-slider .slick-slide.slick-current {
  width: 500px !important;
  display: block;
  opacity: 1 !important;
  margin: 0 15px !important;
}

.recipes-slider .slick-arrow {
  width: 50px;
  height: 50px;
  background: #ffffff url("../images/spirit-icon.png") no-repeat 5px -254px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  top: 25%;
  text-indent: -99999px;
  z-index: 9;
}

.recipes-slider .slick-arrow.slick-prev {
  left: -50px;
  display: none !important;
}

.recipes-slider .slick-arrow.slick-next {
  right: 10px;
  background-position: 20px -65px;
}

.bell-icon {
  background: url("../images/bell-icon.png") no-repeat 0 0;
  background-size: 100%;
}

.recipes-slider-area .bell-icon {
  left: -100px;
  top: 25%;
  z-index: 9;
}

.recipes-slider-area .like-spe {
  top: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 65%;
}

.recipes-slider-area .like-icon {
  top: 9px;
  right: 0;
}

.gallery-icon {
  background: url("../images/gallery-icon.png") no-repeat 0 0;
  background-size: 100%;
}

.recipes-slider-area .gallery-icon {
  bottom: 10px;
  /* bottom: 10px;
    position: relative;
    top: 60rem; */
}

/**Meal Planner Section CSS**/
.meal-plan-section {
  width: 100%;
  padding: 80px 0;
  display: block;
  position: relative;
  min-height: 700px;
}

.meal-plan-section:before {
  content: "";
  position: absolute;
  bottom: 0;
  background: #f8fafb;
  width: 100%;
  height: 80%;
  display: block;
}

.meal-plan-section .title {
  margin: 120px 0 20px 0;
}

.meal-plan-section .shopping-bag {
  width: 45%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url("../images/shopping-bag.png") no-repeat left bottom;
  background-size: contain;
  z-index: 9;
}

.meal-plan-section .shop-list {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  top: 0;
}

.list-icon {
  background: url("../images/list-icon.png") no-repeat 0 0;
  background-size: 100%;
}

.shop-list .list-icon {
  top: 30%;
  left: 55%;
}

.delivery-icon {
  background: url("../images/delivery-icon.png") no-repeat 0 0;
  background-size: 100%;
}

.shop-list .delivery-icon {
  top: 45%;
  left: 32%;
}

.add-icon {
  background: url("../images/plus-icon.png") no-repeat 0 0;
  background-size: 100%;
}

.shop-list .add-icon {
  top: 65%;
  left: 20%;
}

/**Fit Beat & Nutrition Diet Section CSS**/
.fit-beat-section {
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 150px 0 80px;
  position: relative;
  min-height: 700px;
}

.fit-beat-section .women-fitness {
  width: 55%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: url("../images/woman-bg.png") no-repeat top right;
  background-size: 100%;
  z-index: 9;
}

.fit-beat-section .btn {
  margin: 0 0 65px 0;
}

.watch-icon {
  background: url("../images/watch-icon.png") no-repeat 0 0;
  background-size: 100%;
}

.women-fitness .watch-icon {
  top: 40%;
  left: 30%;
}

.apple-icon {
  background: url("../images/apple-icon.png") no-repeat 0 0;
  background-size: 100%;
}

.women-fitness .apple-icon {
  top: 68%;
  right: 35%;
}

/**Download App Section CSS**/
.download-section {
  width: 100%;
  padding: 200px 0;
  margin: 0 auto;
  display: block;
  position: relative;
  min-height: 700px;
}

.download-section:before {
  content: "";
  position: absolute;
  top: 0;
  background: #f8fafb;
  width: 100%;
  height: 90%;
  display: block;
}

.download-section .veg-box {
  width: 45%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 75px;
  background: url("../images/veg-bg.png") no-repeat left bottom;
  z-index: 9;
  background-size: contain;
}

.veg-box .shop-list {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.veg-box .speak-icon {
  top: 15%;
  right: 40%;
}

.veg-box .bell-icon {
  top: 25%;
  left: 75%;
}

.veg-box .list-icon {
  top: 60%;
  left: 75%;
}

/**Testimonials Section CSS**/
.testimonials-section {
  width: 100%;
  display: block;
  padding: 100px 0;
  position: relative;
}

.testimonials-section:before {
  content: "";
  width: 60%;
  height: 100%;
  position: absolute;
  right: 0;
  top: -103px;
  background: url("../images/veg-leave-bg.png") no-repeat top right;
  background-size: 60%;
}

.testimonials-slider {
  padding: 30px;
  background: #ffffff;
  box-shadow: 2px 4px 60px rgba(41, 44, 124, 0.1);
  border-radius: 20px;
}

.test-slide-box {
  width: 100%;
  height: auto;
}

.test-slide-box .testi-content {
  width: 100%;
  display: block;
  padding: 0 30px;
  border-left: 1px solid #616161;
  line-height: 30px;
  font-size: calc(1.25rem + 0.2vw) !important;
}

.test-slide-box .test-title {
  width: 100%;
  display: block;
  padding: 30px 0 0 30px;
  color: #000000;
}

.test-title h3 {
  display: inline-block;
  width: auto;
  font-weight: 700;
  font-size: 24px;
  margin: 25px 0 0 0;
}

.test-title span {
  width: auto;
  float: right;
  display: inline-block;
}

.test-title span strong {
  display: block;
  width: 100%;
  margin: 0;
  text-align: right;
  font-size: 18px;
}

.test-title span i {
  font-size: 18px;
  color: #fba333;
}

.testimonials-slider .slick-arrow {
  width: 50px;
  height: 50px;
  background: #ffffff url(../images/spirit-icon.png) no-repeat 5px -254px;
  box-shadow: 0px 0px 40px rgb(0 0 0 / 25%);
  border-radius: 50px;
  top: 45%;
  text-indent: -99999px;
  z-index: 9;
}

.testimonials-slider .slick-arrow.slick-prev {
  left: -50px;
  display: none !important;
}

.testimonials-slider .slick-arrow.slick-next {
  right: -25px;
  background-position: 20px -65px;
}

/**Footer Section CSS**/
footer {
  width: 100%;
  display: block;
  margin: 0 auto;
  background: #f8fafb url("../images/footer-bg.png") no-repeat top center;
  background-size: 100%;
  padding: 100px 0;
}

.footer-content {
  margin: 0 auto;
  max-width: 720px;
  text-align: center;
}

.footer-content .title strong {
  display: block;
  width: 100%;
  font-weight: 700;
}

.footer-content .foot-inp-box {
  aax-width: 540px;
  width: 100%;
  height: auto;
  padding: 5px;
  background: #ffffff;
  box-shadow: 2px 2px 60px 2px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  display: inline-block;
  margin: 30px 10px auto;
}

.foot-inp-box .form-control {
  width: 80%;
  height: auto;
  line-height: 35px;
  border: none;
  background: none;
  display: block;
  border-radius: 25px;
  padding: 10px 15px;
  float: left;
  margin: 0 !important;
}

.foot-inp-box .news-btn {
  min-width: inherit;
  width: 55px;
  height: 55px;
  float: right;
  text-align: center;
  border-radius: 50%;
  background: #85b492 url("../images/spirit-icon.png") no-repeat 11px -206px;
}

footer .nav-footer,
footer .social-footer {
  width: 100%;
  max-width: 300px;
  display: block;
  text-align: center;
  margin: 30px auto;
}

footer .nav-footer a {
  margin: 0 auto;
  padding: 0 15px;
}

footer .social-footer a,
footer .social-footer b {
  font-size: 14px;
  float: left;
  margin: 5px 0 0 0;
  /* color: black; */
  font-weight: 400;
}

footer .social-footer a {
  width: 50px;
  height: 30px;
  display: inline-block;
  margin: 0;
  background: url("../images/spirit-icon.png") no-repeat;
}

footer .social-footer a.fb-icon {
  background-position: -41px 5px;
}

footer .social-footer a:hover.fb-icon {
  background-position: -41px -35px;
}

footer .social-footer a.twitter-icon {
  background-position: -80px 5px;
}

footer .social-footer a:hover.twitter-icon {
  background-position: -80px -35px;
}

footer .social-footer a.inst-icon {
  background-position: -127px 5px;
}

footer .social-footer a:hover.inst-icon {
  background-position: -127px -35px;
}

/**contact-section CSS**/
.contact-section {
  background: url("../images/contact-sketch.png") no-repeat top right;
  background-size: 70%;
  padding: 125px 0 80px;
  margin: 0 auto;
}

.contact-section .banner-content {
  position: relative;
}

.contact-veg-bag {
  width: 350px;
  height: 800px;
  display: block;
  position: absolute;
  background: url("../images/contact-bg.png") no-repeat 0 0;
  right: 0;
  top: 0px;
  background-size: contain;
}

.contact-section .banner-content .title {
  margin: 50px 0 20px 0;
}

.contact-form {
  width: 100%;
  display: block;
  margin: 50px auto 0;
}

/**Thank-you-area CSS**/
.thank-you-area {
  text-align: center;
  max-width: 550px;
}

.video-box {
  width: 100%;
  margin: 50px auto;
}

.video-fluid {
  width: 100%;
  height: auto;
  border: 1px solid transparent;
}

/**Blog page CSS**/
.inner-page-banner {
  width: 100%;
  height: auto;
  background: #f8fafb url("../images/contact-sketch.png") no-repeat top right;
  background-size: 70%;
  padding: 125px 0 80px;
  margin: 0 auto;
  position: relative;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
}

.inner-img-box {
  width: 45%;
  max-width: 650px;
  height: auto;
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
}

.inner-img-box img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.inner-page-banner .banner-content .title {
  line-height: 50px;
  margin: 100px 0 20px 0;
}

.mid-section {
  padding: 80px 0 0;
  width: 100%;
  display: block;
}

.blog-area {
  margin: 60px auto;
  display: block;
  width: 100%;
}

/* .blog-area .col-md-4 {
  display: none;
} */
.blog-box {
  width: 100%;
  border: 1px solid rgba(133, 180, 146, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  display: block;
  padding: 20px;
  background: #fff;
  margin: 15px auto;
}

.lazyload-wrapper p {
  height: 8.3rem !important;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-box:hover {
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
}

.blog-box .blog-thumbnail {
  width: 100%;
  height: 240px;
  display: block;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 auto 30px;
  position: relative;
  text-align: center;
}

.blog-box .title {
  font-size: 20px;
  line-height: 25px;
  height: 11.4rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-box p {
  color: #616161 !important;
  margin: 0 auto 20px;
}

.blog-box .title span {
  font-size: 12px;
  color: #616161;
}

.blog-box .discover {
  width: 100%;
  display: block;
}

.blog-box .discover strong {
  color: #000;
  font-weight: 600;
  position: relative;
}

.blog-box .discover strong i {
  color: #85b492;
  font-size: 16px;
  margin-left: 5px;
  position: absolute;
  top: 3px;
  right: -15px;
}

.blog-box .discover ul {
  width: auto;
  float: right;
}

.discover ul li {
  float: left;
  text-transform: uppercase;
  font-weight: 600;
  font-size: small;
  color: #85b492;
  font-family: poppins;
}

.discover ul li b {
  margin: 0 3px;
  font-weight: 600;
}

.discover {
  padding-bottom: 2rem;
  height: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-box .blog-thumbnail video {
  text-align: center;
  width: auto;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.blog-box .blog-thumbnail .play-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: -22%;
  font-size: 30px;
  color: #fff;
}

.blog-area .load-more {
  margin: 30px auto 0;
}

.search-box {
  width: 100%;
  background: #ffffff;
  box-shadow: 2px 2px 60px 2px rgb(0 0 0 / 5%);
  border-radius: 100px;
  padding: 12px 20px;
}

.search-box .form-control {
  border: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
}

.search-box .btn {
  width: auto;
  min-width: inherit;
  background: none;
  border: 0;
  padding: 0;
}

.search-box .btn i {
  font-size: 14px;
  color: #85b492;
}

/**Login Section CSS**/
.login-area {
  max-width: 400px;
  margin: 50px auto;
}

.login-area .title {
  text-align: center;
}

.show-pw {
  position: relative;
}

.show-password, .show-password1 {
  color: #85b492;
  height: 25px;
  position: absolute;
  right: 0;
  top: 0;
}


.login-area .custom-checkbox {
  margin: 40px auto 0;
  color: #000;
  padding: 0;
}

.custom-control-input {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  border: 1px solid #85b492;
  background: none;
  position: inherit;
  left: -5px;
  top: 5px;
}

.custom-control-input:checked {
  border: 1px solid #85b492;
  background: #85b492;
}

.custom-control-input label {
  line-height: 24px;
  margin: 0;
  color: #000;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #85b492;
}

.custom-checkbox a {
  color: #85b492;
}

.login-other {
  width: 100%;
  display: block;
  margin: 20px auto 0;
  text-align: center;
}

.login-other h3 {
  width: 100%;
  position: relative;
  display: block;
}

.login-other h3:before {
  content: "";
  width: 100%;
  height: 1px;
  background: rgb(133, 180, 146, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.login-other h3 strong {
  padding: 10px 15px;
  display: inline-block;
  width: auto;
  background: #fff;
  position: relative;
  z-index: 9;
  font-weight: 400;
}

.login-other ul {
  width: auto;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.login-other ul li {
  display: inline-block;
  margin: 0 10px;
}

.login-other ul li a {
  width: 32px;
  height: 30px;
  display: block;
  background: url("../images/spirit-icon.png") no-repeat -61px -88px;
}

.login-other ul li a:hover {
  background-position: -61px -138px;
}

.login-other ul li a.google-icon {
  background-position: -120px -88px;
}

.login-other ul li a:hover.google-icon {
  background-position: -120px -138px;
}

.login-other ul li a.apple-icon {
  background-position: -180px -88px;
}

.login-other ul li a:hover.apple-icon {
  background-position: -180px -138px;
}

/**Blog SsingleS section CSS**/
.single-blog-section {
  background: none;
  padding: 0;
}

.single-blog-section .banner-content {
  margin: 125px 0 0 0;
  padding: 0 0 80px 0;
}

.single-blog-section .banner-content .title {
  line-height: 50px;
  margin: 100px 0 20px 0;
}

.single-blog-section .banner-content,
.single-blog-section .banner-content .title,
.single-blog-section .banner-content .title span {
  color: #fff;
}

.single-blog-section .banner-content .title span strong {
  float: right;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
}

.single-blog-section .single-blog-section .mid-section {
  padding: 0;
}

.blog-single-mid {
  display: block;
}

.blog-submenu {
  width: 100%;
  display: block;
  background: #fafafa;
  padding: 15px 0;
}

.blog-submenu a {
  color: #85b492;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.blog-submenu strong {
  font-weight: 500;
}

.blog-submenu a.active {
  color: #000;
}

.blog-sin-lft {
  display: block;
  margin: 0 auto 60px;
  padding: 0 40px 0 0;
}

.blog-content-area {
  width: 65%;
  display: inline-block;
  font-size: calc(1.25rem + 0.2vw) !important;
}

.share-blog-links {
  width: 30%;
  display: inline-block;
  float: right;
}

.share-blog-links strong {
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  float: left;
  line-height: 30px;
  margin: 0 15px 0 0;
}

.social {
  width: auto;
  float: left;
}

.social li {
  width: auto;
  float: left;
}

.social a {
  width: 40px;
  height: 25px;
  display: inline-block;
  margin: 0;
  background: url("../images/spirit-icon.png") no-repeat;
}

.social .share-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../images/spirit-icon.png") no-repeat -180px -2px;
  position: relative;
  top: 7px;
}


.blog-share a {
  width: 20px;
  height: 25px;
  margin: 0 0 0 5px;
}

.blog-share a.fb-icon {
  background-position: -62px -188px;
}

.blog-share a:hover.fb-icon {
  background-position: -62px -218px;
}

.blog-share a.twitter-icon {
  background-position: -96px -188px;
}

.blog-share a:hover.twitter-icon {
  background-position: -96px -218px
}

.blog-share a.inst-icon {
  background-position: -129px -188px;
}

.blog-share a:hover.inst-icon {
  background-position: -129px -218px;
}

.blog-share a.what-icon {
  background-position: -154px -188px;
}

.blog-share a:hover.what-icon {
  background-position: -154px -218px;
}

.blog-share a.link-icon {
  background-position: -178px -188px;
}

.blog-share a:hover.link-icon {
  background-position: -178px -218px;
}

.blog-sin-lft .single-blog-img {
  width: 100%;
  display: block;
  border-radius: 10px;
  margin: 30px auto;
}

.blog-sin-lft .blog-sub-dive {
  display: block;
  margin: 20px auto 60px;
}

.blog-sin-lft .blog-sin-lft-img {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}

.blog-sin-lft-img img,
.blog-sin-lft-img video {
  text-align: center;
  width: auto;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.author-box {
  width: 100%;
  display: block;
  padding: 30px;
  background: #f8fafb;
}

.author-box .author-img {
  width: 15%;
  max-width: 100px;
  height: 100px;
  border-radius: 10px;
  float: left;
  overflow: hidden;
}

.author-box .author-content {
  width: 85%;
  display: inline-block;
  padding: 0 0 0 20px;
}

.author-social {
  width: auto;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 500;
}

.author-social a {
  color: #85b492;
}

.author-social b {
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background: #000;
  margin: 0 10px;
  position: relative;
  top: -3px;
  display: inline-block;
}

.react-post {
  width: 100%;
  display: block;
}

.post-list {
  width: 100%;
  display: block;
  margin: 0 auto 40px;
}

.post-list span {
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: block;
  margin: 0 auto 20px;
  border-radius: 10px;
}

.post-list span img {
  width: 100%;
  display: block;
}

.post-list p {
  font-size: 12px;
  margin: 0 0 10px 0;
}

.post-list h4 {
  font-size: 16px;
  line-height: 20px;
}

.newsletter-box {
  width: 100%;
  display: block;
  background: #f8fafb;
  border-radius: 10px;
  padding: 25px;
}

.newsletter-box h3 {
  text-align: center;
}

.newsletter-box .search-box {
  box-shadow: none;
  border: 0;
  border-radius: 0;
  background: none;
  padding: 0;
}

.newsletter-box .effect {
  padding: 0;
  background: none;
  color: #000;
  z-index: 11;
}

.newsletter-box .effect~label {
  z-index: 1;
  color: #000;
  text-align: left;
}

.newsletter-box .input-group-append .btn {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  right: 0;
  top: -5px;
  z-index: 9;
  background: url("../images/spirit-icon.png") no-repeat -184px -40px;
}

.newsletter-box .author-social {
  margin: 20px auto 0;
}

.tags-box {
  width: 100%;
  display: block;
  margin: 40px auto;
}

.tags-box a {
  min-width: inherit;
  font-weight: 400;
  margin: 5px 10px 5px 0;
  padding: 5px 15px;
  font-size: 14px;
}

.blog-slider-section {
  width: 100%;
  display: block;
  padding: 60px 0;
  background: #f8fafb;
}

.react-blog-slider .blog-box {
  margin: 30px 30px 0 0;
}

.react-blog-slider .blog-box:hover {
  box-shadow: none;
}

.react-blog-slider .blog-box .blog-thumbnail img {
  width: 100%;
  display: block;
}

.react-blog-slider .slick-arrow {
  width: 50px;
  height: 50px;
  background: #ffffff url(../images/spirit-icon.png) no-repeat 5px -254px;
  box-shadow: 0px 0px 40px rgb(0 0 0 / 25%);
  border-radius: 50px;
  top: 45%;
  text-indent: -99999px;
  z-index: 9;
}

.react-blog-slider .slick-arrow.slick-prev {
  left: -50px;
  display: none !important;
}

.react-blog-slider .slick-arrow.slick-next {
  right: -40px;
  background-position: 20px -65px;
}

.commnet-section {
  width: 100%;
  display: block;
  padding: 80px 0;
  background: url("../images/comment-bg.png") no-repeat top right;
}

.commnet-section .comment-area {
  width: 100%;
  display: block;
}

.comment-area h2 {
  padding: 0 0 40px 0;
}

.commnet-box {
  width: 100%;
  display: block;
}

.commnet-box .author-box {
  background: none;
  padding: 0 0 15px 0;
  margin: 0 0 40px 0;
  border-bottom: 1px solid #85b492;
}

.commnet-box .author-box .profile-box {
  display: block;
  margin: 0 0 20px;
  width: 100%;
}

.commnet-box .author-box .author-img {
  width: 15%;
  max-width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
}

.commnet-box .author-box h4 {
  width: 100%;
  display: inline-block;
  font-weight: 600;
}

.commnet-box .author-box h4 strong {
  width: auto;
  font-weight: 500;
}

.commnet-box .author-box a {
  font-weight: 500;
  font-size: 16px;
  color: #85b492;
  float: right;
}

.commnet-box h4 .date {
  width: auto;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  position: relative;
  padding: 0 0 0 40px;
}

.commnet-box h4 .date:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background: #85b492;
  display: inline-block;
}

.commnet-box .author-content {
  width: 92%;
  padding: 15px 0 0 20px;
}

.commnet-box .sub-comment {
  padding: 0 0 0 50px;
}

.commnet-section .contact-form .mb-5 {
  margin-bottom: 50px;
}

.background-blog {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.errorValidation {
  width: 100%;
  color: #f90004;
}

.footerErrorValidation {
  width: 100%;
  color: #f90004;
  display: flex;
  justify-content: center
}

.watchTutorialModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  z-index: 10;
}

.forgotPasswordModal {
  width: 490px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;

}
.forgotPasswordModal{
  min-height: 318px;
  max-height: 10vh;
}


/* custom scroll bar end */
/* ===== Scrollbar CSS ===== */
/* Firefox */
.forgotPasswordModal {
  scrollbar-width: auto;
  scrollbar-color: #8f54a0 #ffffff;
}

.forgotPasswordModal::before{
  content: "Forget Password";
  position: fixed;
  background-color: #85B492;
  color: white;
  height: 50px;
  right: 0;
  left: 0;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}
.add-ingredient-modal{
  width: 490px;
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
border: 1px solid #ccc;
background: #fff;
overflow: auto;
  overflow-y: auto;
border-radius: 4px;
outline: none;
padding: 20px;
  
}
/* .add-ingredient-modal::before{
  content: "Add Ingredients";
  position: fixed;
  background-color: #85B492;
  color: white;
  height: 50px;
  right: 0;
  left: 0;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
} */


/* Chrome, Edge, and Safari */
.forgotPasswordModal::-webkit-scrollbar {
  width: 16px;
}

.forgotPasswordModal::-webkit-scrollbar-track {
  background: #ffffff;
}

.forgotPasswordModal::-webkit-scrollbar-thumb {
  background-color: #85B492;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
/* custom scroll bar end */

.forgotPasswordModal ol {
  list-style: none;
  text-decoration: none;
}

.forgotPasswordModal ol li{
  display: flex;
  max-width: 100%;
  padding: 3px 0px;
}

.forgotPasswordModal ol li:first-child{
  margin-top: 20px;
}

.forgotPasswordModal ol li div{
  word-wrap: break-word;
  max-width: 90%;
  align-self: center;
}


.forgotPasswordModal ol li::before {
 content: attr(data-list-index);
 display: inline-block;
 padding: 3px 5px;
 font-size: 18px;
 font-weight: bolder;
 position: relative;
 top: 1px;
 color: rgb(133, 180, 146);
}

.add-ingredient-box ol{
  list-style: none;
  text-decoration: none;
}

.directionListModal{
  padding-top: 70px;
}

.directionListModal::before{
  content: "Add Directions";
  position: fixed;
  background-color: #85B492;
  color: white;
  height: 50px;
  right: 0;
  left: 0;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}
.add-ingredient-box ol li{
  display: flex;
  justify-content: flex-start;
  /* justify-content: space-between; */
  align-items: center;
  word-wrap: break-word;
  padding-bottom: 10px;
  padding-top: 15px;
  /* border-bottom: 1px solid black; */
  position: relative;
}
.add-ingredient-box ol li:first-child{
  margin-top: 50px;
}

.add-ingredient-box ol li::after{
  content: "";
  position: absolute;
  background-color: #85B492;
  /* width: 336px; */
  height: 2px;
  opacity: .3;
  bottom: 0px;
  /* left: 35px; */
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}
/* .add-ingredient-box ol li:last-child:after{
  height: 0px;
} */
.add-ingredient-box ol li div {
  /* width: 100%; */
  max-width: 100%;
  align-self: center;
  word-wrap: break-word;
  font-family: poppins;
  
}
.add-ingredient-box ol li .penToSquareIcon{
  /* width: max-content; */
  align-self: center;
  justify-self: flex-end;
}
.add-ingredient-box ol li .trashIcon{
  align-self: center;
  /* width: max-content; */
  justify-self: flex-end;

}

.add-ingredient-box ol li::before {
 content: attr(data-list-index);
 display: inline-block;
 padding: 3px 5px;
 font-size: 18px;
 font-weight: bolder;
 position: relative;
 top: 1px;
 color: rgb(133, 180, 146);
 align-self: flex-start;
}

.btn-add-directions,
.btn-add-ingredient{
  margin-right: auto;
  margin-left: auto;
  display: block;
  margin-top: 15px;
}

.add-directions-heading,
.add-ingredient-heading{
  text-align: center;
  margin-bottom: 13px
}

.ingredient-modal{
  min-height: 500px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  z-index: 1000;
  width: 800px;
}

/* custom scroll bar end */
/* ===== Scrollbar CSS ===== */
/* Firefox */
.ingredient-modal {
  scrollbar-width: auto;
  scrollbar-color: #8f54a0 #ffffff;
}

/* Chrome, Edge, and Safari */
.ingredient-modal::-webkit-scrollbar {
  width: 16px;
}

.ingredient-modal::-webkit-scrollbar-track {
  background: #ffffff;
}

.ingredient-modal::-webkit-scrollbar-thumb {
  background-color: #85B492;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
/* custom scroll bar end */

.ingredient-modal ol li:hover{
  color: #85B492;
}

.ingredient-modal ol li a{
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-directions-heading span,
.add-ingredient-heading span{
  font-size: 20px;
}

.forgotPasswordOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 30;
}

/* .ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
} */

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.spinner {
  width: 100%;
  height: 100%;
}

.spinner>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ul-btn li.btn {
  position: relative;
}

.google-btn.tooltip {
  left: 0;
  top: 60px;
  margin: 0 auto;
  min-width: 80px;
  height: 0;
  display: none;
}

.google-btn.tooltip img {
  width: 100%;
}

.ul-btn li.btn a:hover .google-btn.tooltip {
  opacity: 1;
  height: auto;
  display: block;
}


.creatblog {
  animation: blinker-two 1.0s linear infinite;
  width: 70px;
  height: 70px;
  background-color: #85b492;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 #666;
  transition: all 0.1s ease-in-out;

  font-size: 50px;
  color: white;
  text-align: center;
  line-height: 70px;

  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 30;
}

.creatblog:hover {
  box-shadow: 3px 3px 3px 3px rgb(17, 210, 188);
  transform: scale(1.1);
  z-index: 30;
}

.create-area {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.file-upload .file-upload-select {
  display: block;
  cursor: pointer;
  text-align: left;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #85B492
}

.file-upload .file-upload-select .file-select-button {
  display: inline-block;
}

.file-upload .file-upload-select .file-select-name {
  display: inline-block;
  padding: 10px;
}

.file-upload .file-upload-select:hover .file-select-button {
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-upload-select input[type="file"] {
  display: none;
}


.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
  width: 50rem;
}


/* .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    border: 1px solid #eee;
    min-width: 150px;
  } */

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInpGutField:focus {
  height: 31px;
  margin: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  font-size: 12px;
  border-bottom: 1px solid #85b492;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #85b492;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 5px;
}

.ReactTags__selected span.ReactTags__tag:first-child {
  margin-left: 0px;
  margin-bottom: 3px;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
  top: 60px;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}


@keyframes blinker-two {
  100% {
    opacity: 0;
  }
}


.btn-register-header {
  width: auto;
  min-width: 120px !important;
  line-height: 15px;
}


.btn-download-app-get-started {
  width: auto;
  min-width: 150px;
  line-height: 15px;
  font-size: 13px;
}

.btn-download-app {
  width: auto;
  min-width: 150px;
  line-height: 20px;
  font-size: 13px;
}


.banner-content .title-register {
  line-height: 50px;
  margin: 200px 0 20px 0;
}

.login-area .title-register {
  text-align: center;
}

.title-register {
  width: 100%;
  font-size: 45px;
  line-height: 42px;
  font-weight: 700;
  margin: 0 0 20px 0;
  padding: 0;
}

.contact-section .banner-content .title-register {
  margin: 50px 0 20px 0;
}

.title-register span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #85b492;
  display: block;
  margin: 0 0 15px 0;
}

.inner-page-banner .banner-content .title-register {
  line-height: 50px;
  margin: 100px 0 20px 0;
}

p1 {
  margin: 0 0 20px;
  font-size: 14px;
}

.title-fold-1-landing {
  font-size: 5.1rem;
  line-height: 50px;
}

.p-landing {
  margin: 0 0 20px 0;
  /* font-size: 15px; */
  font-size: calc(1.25rem + 0.2vw);
}

.title-landing {
  width: 100%;
  font-size: 34px;
  line-height: 42px;
  font-weight: 700;
  margin: 0 0 20px 0;
  padding: 0;
}

.recent-posts {
  font-size: 25px;
}

.p-comments {
  font-size: 16px;
  margin: 0 0 20px 0;
  font-weight: 400;
  color: #707070;
}

.b-comment {
  font-size: 36px;
  line-height: 42px;
  font-weight: 500;
}

.banner-section-create-blog {
  width: 100%;
  display: block;
  padding: 0;
  margin: 150px auto 200px;
  /* background: url(http://localhost:3000/static/media/banner-bg.ca31704….png) no-repeat top left; */
  position: relative;
  background-size: contain;
}

.banner-section-landing-page {
  width: 100%;
  display: block;
  padding: 0;
  margin: 100px auto 200px;
  /* background: url(http://localhost:3000/static/media/banner-bg.ca31704….png) no-repeat top left; */
  position: relative;
  background-size: contain;
}

.blog-page-title {
  font-size: 49px !important;
}

.banner-section-backgroung-img {
  width: 100%;
  display: block;
  padding: 0;
  margin: 100px auto 200px;
  background-image: url('../images/sketch-fold-1-bg.jpg');
  background-repeat: no-repeat;
  background-position: top-left;
  position: relative;
  background-size: 58%;
}

.blog-paragraph-post {
  font-size: 14px !important;
}

.dropbtn {
  background-color: #ffffff;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  border: 2px solid #ffffff;
  border-radius: 25px;
  width: auto;
  min-width: 40px;
  padding: 10px 15px;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
}

.dropdown {
  position: relative;
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    /* padding: 5px 5px; */
    z-index: 1;
    right: 0;
}

.dropdown-content a {
  color: black;
  padding: 5px 1px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  /* background-color: #; */
}

.tags-box-workspace {
  width: 100%;
  display: block;
  margin: 40px auto;
}

.tags-box-workspace a {
  min-width: inherit;
  font-weight: 400;
  margin: 5px 10px 5px 0;
  padding: 4px 13px;
  font-size: 11px;
}

.my-account-form {
  border-radius: 10px;
  padding: 3px 10px !important;
  border: 1px solid #676666 ;
  opacity: 30%;

}
/* .my-account-form-label{
  color: ;

} */

.varified {
  margin-right: 0.5rem;
}

.current_plan {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 9px 0;
  text-align: center;
  color: #ffffff;
  /* background: linear-gradient( #85b492); */
  background-color: #85b492;
  border-radius: 10px 10px 0px 0px;
}

.manage-subscription-fold-1 {
  border-bottom: 1px dashed #a9afc342;
  min-height: 170px;
}

.manage-subscription-fold-2 {
  border-bottom: 1px dashed #a9afc342;
  min-height: 250px;
}

.manage-subscription-fold-3 {
  /* border-bottom: 1px dashed #a9afc342; */
  min-height: 150px;
}

.checkout-manage-subscription-fold-1 {
  border-bottom: 1px dashed #a9afc342;
  min-height: 100px;
}

.checkout-manage-subscription-fold-2 {
  border-bottom: 1px dashed #a9afc342;
  min-height: 250px;
}

.checkout-manage-subscription-fold-3 {
  /* min-height: 80px; */
}


.my-account-apply {
  float: right;
}

.my-account-plan-price {
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 34px;
  letter-spacing: -0.02em;
  background-color: #85b492;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.apple-pay-btn {
  min-width: 342px;
}

.card {
  position: absolute;
  top: 200px;
  right: 20px;
  width: 38%;
}

.price_tab {
  color: #000;
  transition: 0s;
  background-color: transparent;
  font-size: 19px;
  border: none;
}

.price_tab:hover {
  background: linear-gradient(65.49deg, #28a745 7.03%, #28a745 45.79%, #28a745 73.14%, #28a745 87.94%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.price_tab.active {
  background-color: transparent;
  /* background: linear-gradient(65.49deg, #FCB854 7.03%, #D96534 45.79%, #DC4E4F 73.14%, #DD425D 87.94%); */
  background: linear-gradient(65.49deg, #28a745 7.03%, #28a745 45.79%, #28a745 73.14%, #28a745 87.94%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: none;
}

body.light-mode .price_tab {
  color: #ffffff;
}

.my-account-image-upload {
  display: none !important;
}

.small-p{
  font-size: 12px !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 230px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
  border-radius: 5px;
}

/* 20220913 my account page design changes*/
.profile-pic-container{
  width: 100px;
}

.profile-pic{
  position: relative;;
}
.profile-pic .profile-img{
  height: 150px;
  width: 150px;
}
.upload_label{
  position: absolute;
  right: 4%;
  bottom: 5%;
}

.password-input-container{
  position: relative;
}

.password-input-container input[type="password"]{
  width: 100%;
}
.password-input-container span{
  position: absolute;
  height: 25px;
  color: #85b492;
  top: 35px;
  right: 10px;
}

.password-confirm span{
  top: 10px;
  right: 10px;
}

.contact-detail input{
  color: #616161;
  opacity: 1;
}

.contact-detail input::placeholder{
  color: #979797;
}

.recurring-image-before::before{
  content: "";
  display: inline-block;
  background-image: url("../images/monthly_recurring.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  margin: 2px 4px;
  position: relative;
  top: 6px
}

.toggle-form{
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  /* align-items: flex-start; */
}


#toggle-element{
  display: none;
}

.toggle__decor{
  background-color: whitesmoke;
  height: 23px;
  width: 59px;
  border-radius: 15px;
  position: relative;
  font-size: 9px;
  font-weight: bolder;
}


.toggle-text-off{
  position: relative;
  right: -20px;
  top: 6px;
  opacity: 1;
  transition: opacity .3s ease;
}

.toggle-text-on{
  position: relative;
  left: 5px;
  top: 6px;
  opacity: 0;
  transition: opacity .3s ease;
}
#toggle-element:checked ~ .toggle__decor > .toggle-text-on{
  opacity: 1;
  color: white;
  
}
#toggle-element:checked ~ .toggle__decor > .toggle-text-off{
  opacity: 0;
}
/* on checked */
/* .toggle-text-on{
  left: 36px;
} */

.toggle__thumb{
  position: absolute;
  z-index: 10;
  background-color: #85b492;
  border-radius: 50%;
  height: 19px;
  width: 19px;
  top: 2px;
  left: 3px;
  transition: transform .3s ease;
}
#toggle-element:checked ~ .toggle__decor > .toggle__thumb{
  /* left: 36px !important; */
  background-color: white;
  transform: translateX(34px);
} 

#toggle-element:checked ~ .toggle__decor{
  background-color: #85b492;
}

.current_plan{
  position: relative;
  top: -20px;
}




.upload-image-container{
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  margin-top: 5px;
  margin-top: 15px;
}
.upload-image-container .image-container{
  width:100px;
  height: 100px;
  position: relative;
  /* width:100px; */
  /* height: 100px; */
}
.upload-image-container .upload-image{
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.upload-image-container label{
  width: 100px;
}
.image-delete{
  position: absolute;
  right: -6px;
  top: -25px;
  z-index: 100;
  font-weight: bolder;
  height: 30px;
  width: 30px;
  font-size: 20px;
  /* background-color: white; */
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  /* border: 3px solid red; */
}
.image-delete-video{
  right: 12px;
  top: -32px;
  font-size: 25px;
}
.video-input{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.VideoInput{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.video-upload-section{
  padding: 0;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}
/* 20220914 adding image from api to ingredients list */
.modal-ing-img{
  height: 50px;
  width: auto;
  transition: transform .3s ease;
}
.modal-ing-img:hover{
  transform: scale(1.30);
}
.ingredient-name{
  margin: 0;
  flex: 1;
  /* flex: 1 !important; */
}
[data-icon="square-check"] ~ .modal-ing-img{
  transform: scale(1.20);
}
.ingredient-search-field{
  margin-top: 50px;
  border-radius: 20px;
  border-color: #85B492;
  border: solid;
  outline: none;
}
#ingredient-search{
  padding: 5px 10px;
}
.ingredient-name-in-list{
  flex: 1;
}
button.slick-arrow{
  display: block;
    background: #85B492;
    color: yellow;
    border-radius: 50%;
}
.header-dropdown{
  color: #676666 !important;
  font-weight: 500 !important;
}
.user-name{
  color: #85B492;
font-weight: 600;
font-size: 15px;
}
.add-ingredient-model::before{
  content: "Add Ingredients";
position: fixed;
background-color: #85B492;
color: white;
height: 50px;
right: 0;
left: 0;
top: 0px;
display: flex;
justify-content: center;
align-items: center;
font-size: 22px;
}
.password-forgot-modal{
  min-height: 427px !important;
  max-height: 10vh !important;
}
.ingredient-quantity-model::before{
  content: "Add Quantity" !important;
  position: fixed;
  background-color: #85B492;
  color: white;
  height: 50px;
  right: 0;
  left: 0;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}
.incorrect-password-modal::before{
  content: "Incorrect Password" !important;
  position: fixed;
  background-color: #85B492;
  color: white;
  height: 50px;
  right: 0;
  left: 0;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}
.incorrect-password{
  min-height: 427px !important;
max-height: 10vh !important;
}
.ingredient-box-model{
  min-height: 450px;
max-height: 10vh;
}

html,body{
  margin: 0;
  padding: 0%;
  overflow-x: hidden;
}
html{
  height: 100%;
}

.switch-account-plan{
padding: 4rem;
border-radius: 5px;
border: 2px solid #85B492;
color: #707070;
}
.right-angle{
  font-size: 51px !important;
}
.container-border{
  border: 2px solid #85B492;
  border-radius: 10px;
}
.plan-modal-paragraph{
  font-size: 17px;
  font-family: poppins;
  color: #707070;
}
.cancel-btn{
  color: #707070;
}
.switch-plan-modal-box{
display: flex;
flex-direction: column;
align-items: center;
padding: 2rem;
border: 2px solid #707070;
border-radius: 10px;
color: #707070;
font-family: poppins;
line-height: 35px;
}
.right-arrow{
  font-size: 50px;
  color: #707070;
}
.decoration{
  text-decoration: underline;
}
.payable-container{
font-family: poppins;
font-size: 15px;
color: #707070;

}
.heading-bottom{
  color: #85B492;
}

.subscription-preview-modal-upgrade {
  width: 600px;
  height: 700px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;

}

.subscription-preview-modal-downgrade {
  width: 500px;
  min-height: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;

}

.edit-drope-down{
  width: 100%;
    border: 1px solid rgba(133, 180, 146, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
    display: none;
    background: #fff;
}

/* .edit-drope-down-hide{
  width: 100%;
    border: 1px solid rgba(133, 180, 146, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    display: none;
} */


.trashIcon:hover ~ .editWrapper > .edit-drope-down{
  display: block;
}