/***********~Media Query~***********/
@media only screen and (min-width: 2560px) {}

@media only screen and (min-width: 1920px) {
  .banner-section {
    margin: 100px auto 300px;
  }

  .banner-slider {
    max-width: 625px;
    right: 10%;
  }

  .live-device-area {
    left: 10%;
  }

  .recipes-slider-area {
    width: 35%;
  }

  .fit-beat-section .women-fitness {
    width: 40%;
  }

  .testimonials-section:before {
    right: 10%;
  }

  .contact-section {
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1440px) {}

@media only screen and (max-width: 1366px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 34px;
    margin: 0 0 20px 0;
    line-height: 40px;
  }

  h2 {
    font-size: 32px;
    line-height: 35px;
  }

  h3 {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 15px 0;
  }

  p {
    margin: 0 0 20px 0;
    /* font-size: 14px; */
  }

  .meal-plan-section:before {
    height: 85%;
  }
}

@media only screen and (max-width: 1365px) {
  .title {
    width: 100%;
    font-size: 40px;
    line-height: 40px;
  }

  .banner-slider .dish__nav {
    overflow: hidden;
    text-align: center;
    margin: -50px auto 0;
  }

  .live-device-area .live-device-box {
    width: 575px;
  }

  .live-device-screen .speak-icon {
    right: -70px;
  }

  .recipes-slider-area .bell-icon {
    left: -20px;
  }

  .meal-plan-section {
    padding: 0;
    margin: 80px auto 0;
  }

  .meal-plan-section:before {
    height: 100%;
  }

  .meal-plan-section .title {
    margin: 80px 0 20px 0;
  }

  .meal-plan-section .btn {
    margin: 0 0 80px 0;
  }

  .fit-beat-section .women-fitness {
    width: 50%;
  }

  .women-fitness .watch-icon {
    top: 30%;
  }

  .women-fitness .apple-icon {
    top: 48%;
  }

  .fit-beat-section .btn {
    margin: 0 0 50px 0;
  }
}

@media only screen and (min-width: 1280px) {}

@media only screen and (max-width: 1279px) {
  h1 {
    font-size: 28px;
    margin: 0 0 20px 0;
    line-height: 35px;
  }

  h2 {
    font-size: 26px;
    line-height: 30px;
  }

  h3 {
    font-size: 18px;
    line-height: 30px;
    margin: 0 0 15px 0;
  }

  .blog-box .blog-thumbnail {
    height: 180px;
  }

  .banner-section {
    margin: 100px 0;
  }

  .banner-content .title {
    line-height: 50px;
    margin: 150px 0 20px 0;
  }

  .allergy-section {
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 250px 0 80px;
  }

  .recipes-slider .slick-slide.slick-current {
    width: 420px !important;
  }

  .commnet-section {
    background-size: 30%;
  }
}

@media only screen and (max-width: 991px) {
  h1 {
    font-size: 32px;
    margin: 0 0 20px 0;
    line-height: 36px;
  }

  h2 {
    font-size: 30px;
    line-height: 34px;
  }

  h3 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 15px 0;
  }

  /**Banner Section CSS**/
  .nav-links {
    margin: 30px 20px 0 0;
  }

  .nav-links li {
    position: relative;
    margin: 0 0 0 15px;
  }

  .banner-section {
    width: 100%;
    display: block;
    padding: 0;
    margin: 150px auto 80px;
  }

  .banner-slider {
    position: relative;
    width: 100%;
    margin: 0 auto;
    left: inherit;
    right: inherit;
  }

  .banner-content .title {
    line-height: 46px;
    margin: 50px auto 20px;
  }

  .banner-slider .dish__nav {
    margin: 0 auto;
  }

  .banner-slider .like-spe .speak-icon {
    left: 9px;
    top: -44px;
  }

  .banner-slider .like-spe .like-icon {
    left: 19px;
    top: 211px;
  }

  .like-spe .cook-dish {
    width: auto;
    top: 99px;
    left: -71px;
  }

  .live-device-area .live-device-box {
    position: relative;
  }

  .live-device-area {
    width: 100%;
    position: relative;
    height: 80vh;
    min-height: inherit;
  }

  .live-device-box .scr-ply-btn .tooltip {
    left: 0;
    top: 80px;
  }

  .allergy-section {
    padding: 80px 0;
    /* max-height: 1100px; */
  }

  .recipes-slider-area {
    width: 100%;
    display: block;
    position: relative;
    top: inherit;
    margin: 60px auto 0;
  }

  .recipes-slider-area .gallery-icon .tooltip {
    left: 0;
    top: 80px;
  }

  .recipes-slider-area .like-spe {
    top: 0;
    height: 100%;
  }

  .recipes-slider-area .bell-icon {
    left: 10px;
    top: 25%;
    z-index: 9;
  }

  .recipes-slider .slick-list {
    height: auto;
  }

  .recipes-slider .slick-slide.slick-current {
    width: 640px !important;
  }

  .recipes-slider .slick-arrow.slick-next {
    top: 45%;
  }

  .meal-plan-section {
    padding: 60px 0 0;
  }

  .meal-plan-section:before {
    height: 100%;
  }

  .meal-plan-section .title {
    margin: 0 0 20px 0;
  }

  .meal-plan-section .shopping-bag {
    width: 100%;
    height: 60vh;
    position: relative;
    display: inline-block;
    left: inherit;
    top: inherit;
    margin: 60px auto 0;
    background-size: contain;
    z-index: 9;
  }

  .shop-list .list-icon {
    top: 30%;
    left: 43%;
  }

  .shop-list .delivery-icon {
    top: 40%;
    left: 24%;
  }

  .shop-list .add-icon {
    top: 64%;
    left: 13%;
  }

  .fit-beat-section {
    padding: 80px 0;
  }

  .fit-beat-section .women-fitness {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 65vh;
  }

  .fit-beat-section .btn {
    margin: 0;
  }

  .women-fitness .watch-icon {
    top: 45%;
    left: 30%;
  }

  .women-fitness .apple-icon {
    bottom: 17%;
    top: inherit;
    right: 35%;
  }

  .download-section {
    padding: 80px 0 0;
  }

  .download-section .veg-box {
    width: 100%;
    height: 85vh;
    position: relative;
    left: 0;
    top: 0;
    background-size: contain;
  }

  .veg-box .bell-icon .tooltip {
    left: -220px;
  }

  .testimonials-section:before {
    display: none;
  }

  .veg-box .tooltip.listtip {
    left: -220px;
    top: 50px;
  }

  .testimonials-section {
    padding: 80px 0;
  }

  /**Contact us page css**/
  .contact-section .banner-content .title {
    margin: 150px 0 20px 0;
  }

  .contact-veg-bag {
    width: 90%;
    height: 70vh;
    position: absolute;
    right: 25px;
    top: 28px;
    background-size: contain;
  }

  .testimonials-slider {
    margin: 60px auto 0;
    padding: 30px 15px;
  }

  .testimonials-slider .slick-slide {
    padding: 0 30px;
  }

  .test-slide-box .testi-content {
    padding: 0 0 0 30px;
  }

  footer {
    padding: 80px 0;
  }

  .dropdown-select {
    margin: 0 auto 15px;
  }

  .blog-area {
    margin: 40px auto;
  }

  .blog-thumbnail img {
    height: 100%;
    width: auto;
    max-width: inherit;
  }

  .blog-sin-lft {
    padding: 0;
  }

  .blog-content-area {
    width: 100%;
  }

  .share-blog-links {
    width: 100%;
    float: left;
    margin: 0 0 30px 0;
  }

  .blog-sin-lft-img img,
  .blog-sin-lft-img video {
    text-align: center;
    width: 100%;
    height: auto;
    position: relative;
    left: inherit;
    right: inherit;
    top: inherit;
    bottom: inherit;
    margin: 0 auto;
  }

  .blog-sin-lft .blog-sub-dive {
    display: block;
    margin: 20px auto 40px;
  }

  .live-stream-section .title {
    margin: 200px 0 20px 0;
  }

  .live-stream-section {
    width: 100%;
    margin: 0 auto;
    padding: 0 0 80px;
  }

  .commnet-section {
    background-image: none;
  }
  .recipes-slider-area .like-icon {
    top: -28px;
    right: 0;
  }
  .ingredient-modal {
    min-height: 500px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    z-index: 1000;
    width: 696px;
  }
}

@media only screen and (max-width: 768px) {
  .watchTutorialModal {
    width: 80%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 1px;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 24px;
    margin: 0 0 20px 0;
    line-height: 28px;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
  }

  h3 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 15px 0;
  }

  .desktop-display {
    display: none;
  }

  .device-display {
    display: block;
  }

  /**Responsive Menu CSS**/
  nav {
    position: relative;
    z-index: 3;
    width: 100%;
    height: auto;
    display: flex;
    top: 0;
    left: 0;
  }

  .hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 50%;
    width: 40px;
    text-align: right;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.7s ease;
  }

  .nav-links {
    position: fixed;
    background: #85b492;
    height: 100vh;
    width: 90%;
    opacity: 0;
    flex-direction: column;
    clip-path: circle(50px at 90% -20%);
    -webkit-clip-path: circle(50px at 90% -10%);
    transition: all 1s ease-out;
    pointer-events: none;
  }

  .nav-links.open {
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
    opacity: 1;
    height: 60vh;
    top: 60px;
    border-radius: 5px;
  }

  .nav-links li {
    opacity: 0;
    margin: 0 auto;
  }

  .nav-links li a,
  .nav-links li a.btn {
    color: #000;
  }

  .nav-links li a.active {
    color: #fff;
  }

  .nav-links li:nth-child(1) {
    transition: all 0.5s ease 0.2s;
  }

  .nav-links li:nth-child(2) {
    transition: all 0.5s ease 0.4s;
  }

  .nav-links li:nth-child(3) {
    transition: all 0.5s ease 0.6s;
  }

  .nav-links li:nth-child(4) {
    transition: all 0.5s ease 0.7s;
  }

  .nav-links li:nth-child(5) {
    transition: all 0.5s ease 0.8s;
  }

  .nav-links li:nth-child(6) {
    transition: all 0.5s ease 0.9s;
    margin: 0;
  }

  .nav-links li:nth-child(7) {
    transition: all 0.5s ease 1s;
    margin: 0;
  }

  li.fade {
    opacity: 1 !important;
  }

  li.fade .btn {
    padding: 0;
    color: #000;
  }

  li.fade .btn:hover {
    background: none;
  }

  .hamburger .line1 {
    width: 25px;
    float: right;
  }

  .hamburger .line2,
  .hamburger .line3 {
    float: left;
  }

  .toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .toggle .line2 {
    transition: all 0.3s ease;
    width: 0;
  }

  .toggle .line3 {
    transform: rotate(45deg) translate(10px, -12px);
    width: 24px;
  }

  /**Banner Section CSS**/
  .ul-btn li,
  .ul-btn li.tutorial {
    width: 100%;
    max-width: 250px;
    display: block;
    margin: 0 auto 15px;
    text-align: center;
    line-height: 35px;
  }

  .tooltip {
    top: -10px;
  }

  .green-circle .tooltip.left {
    left: inherit;
    right: 49px;
  }

  /**Banner Section CSS**/
  .banner-section {
    margin: 0 auto;
    margin-top: 40px;
  }

  .banner-section-create-blog {
    margin: 0 auto;
    margin-top: 120px;
  }

  .title {
    font-size: 35px;
    line-height: 40px;
    width: 80%;
    margin: 30px auto 20px;
  }

  .title span {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 5px 0;
  }

  .banner-content .title {
    line-height: 40px;
    margin: 150px auto 20px;
    max-width: 80%;
  }

  .banner-slider {
    position: relative;
    width: 100%;
    margin: 0 auto;
    left: inherit;
    right: inherit;
  }

  .logo {
    max-width: 150px;
  }

  header.sticky .logo {
    max-width: 120px;
  }

  .banner-slider .dish__nav {
    margin: 0 auto;
  }

  .green-circle {
    width: 40px;
    height: 40px;
  }

  .banner-slider .like-spe .speak-icon {
    left: 0;
    top: -52px;
  }

  .banner-slider .like-spe .like-icon {
    /* left: -7px;
    bottom: 47px; */
    left: -7px;
    top: 141px;
  }

  .like-spe .cook-dish {
    width: auto;
    top: 200px;
    left: -31px;
  }

  .like-spe .speak-icon {
    top: 0;
    left: -35px;
  }

  .like-spe .cook-dish {
    top: 42px;
    left: -84px;
  }

  .like-spe .like-icon {
    bottom: 40px;
  }

  /**Live Stream Section CSS**/
  .live-stream-section {
    padding: 60px 0;
  }

  .live-device-area .live-device-box {
    position: relative;
  }

  .live-device-area {
    width: 100%;
    position: relative;
    height: 100%;
    min-height: inherit;
    max-height: 500px;
  }

  .live-device-area .live-device-box {
    width: 100%;
    height: 115vh;
    display: block;
    margin: 0 auto;
    background: url("../images/device-bg.png") no-repeat top center;
    background-size: inherit;
    top: 50px;
  }


  .live-device-screen {
    position: inherit;
    left: inherit;
    top: 75px;
    right: -10px;
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
    background: none;
    background-size: 100%;
  }

  .live-device-screen:before {
    background: none;
  }

  .live-device-screen .screen-video {
    height: 150px;
    overflow: hidden;
    border-radius: 5px;
  }

  .live-device-screen .speak-icon {
    right: -15px;
  }

  .scr-ply-btn {
    top: 25%;
    right: -15px;
  }

  .upload-icon {
    display: none;
  }

  .live-stream-section .title {
    /* margin: 50px 0 20px 0; */
    width: 80%;
    margin: 0 auto 30px;

  }

  .live-device-screen .tooltip {
    left: inherit !important;
    right: 50px;
    top: 0 !important;
  }

  /**Allergy Alert & Recipes Section CSS**/
  .allergy-section {
    padding: 60px 0;
    position: relative;
    overflow: hidden;
  }

  .recipes-slider-area {
    width: 100%;
    display: block;
    position: relative;
    top: inherit;
    margin: 60px auto 0;
  }

  .recipes-slider .slick-slide {
    margin: 0px 20px !important;
    width: 330px !important;
  }

  .recipes-slider .slick-slide.slick-current {
    width: 330px !important;
    margin: 0px 20px !important;
  }

  .recipes-slider .slick-slide img {
    width: 100%;
    height: auto;
    display: block;
  }

  .recipes-slider-area .like-icon {
    top: 0;
    right: 0;
  }

  .recipes-slider-area .gallery-icon .tooltip {
    left: 0;
    top: 45px;
  }

  .recipes-slider-area .like-icon .tooltip {
    left: -175px;
    top: 45px;
  }

  .recipes-slider-area .like-spe {
    height: 100%;
  }


  .recipes-slider-area .bell-icon {
    left: 15px;
    top: 13%;
    z-index: 9;
  }

  .recipes-slider .slick-arrow.slick-next {
    right: 50px;
    background-position: 20px -65px;
    top: 35%;
  }

  /**Meal Planner Section CSS**/
  .meal-plan-section {
    padding: 60px 0 0;
  }

  .meal-plan-section:before {
    height: 100%;
  }

  .meal-plan-section .title {
    /* margin: 0 0 20px 0; */
    width: 80%;
margin: 30px auto 20px;
  }

  .meal-plan-section .shopping-bag {
    width: 100%;
    height: 60vh;
    position: relative;
    display: inline-block;
    left: inherit;
    top: inherit;
    margin: 60px auto 0;
    background-size: contain;
    z-index: 9;
  }

  .tooltip.listtip {
    left: -100px;
    top: 50px;
  }

  .listtip.shopping {
    left: 0;
    top: 50px;
  }

  /**Fit Beat & Nutrition Diet Section CSS**/
  .fit-beat-section {
    padding: 60px 0 0;
  }

  .fit-beat-section .btn {
    margin: 0;
  }

  .fit-beat-section .women-fitness {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 53vh;
  }

  .women-fitness .apple-icon .tooltip {
    left: -167px;
    top: 50px;
  }

  .veg-box .bell-icon .tooltip {
    left: -220px;
  }

  .veg-box .tooltip.listtip {
    left: -220px;
    top: 50px;
  }

  /**Download App Section CSS**/
  .download-section {
    padding: 60px 0;
  }

  .download-section .veg-box {
    width: 100%;
    height: 85vh;
    position: relative;
    left: 0;
    top: 0;
    background-size: 100%;
  }

  /**Testimonials Section CSS**/
  .testimonials-section {
    padding: 60px 0;
  }

  .testimonials-section:before {
    display: none;
  }

  .testimonials-slider {
    margin: 60px auto 0;
    padding: 30px 15px;
  }

  .testimonials-slider .slick-slide {
    padding: 0 15px;
  }

  .test-slide-box .testi-content {
    padding: 0 0 0 15px;
  }

  .test-slide-box .test-title {
    padding: 30px 0 0 0;
    color: #000000;
  }

  .test-title h3 {
    margin: 0;
  }

  .testimonials-slider .slick-arrow.slick-next {
    right: -10px;
  }

  .shop-list .list-icon {
    top: 30%;
    left: 52%;
  }
  .veg-box .bell-icon {
    top: 8%;
    left: 75%;
  }

  .shop-list .delivery-icon {
    top: 39%;
    left: 30%;
  }

  /**Footer Section CSS**/
  footer {
    padding: 60px 0;
  }

  footer .social-footer a,
  footer .social-footer b {
    padding: 0 5px;
  }

  /**Blog page CSS**/
  .inner-page-banner .banner-content .title {
    line-height: 40px;
    margin: 0 0 20px 0;
  }

  .inner-page-banner {
    height: auto;
    min-height: inherit;
    padding: 30px 0 0;
  }

  .inner-img-box {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    right: 0;
    bottom: 0;
  }

  .contact-section .banner-content .title {
    margin: 150px 0 20px 0;
  }

  .login-area .custom-checkbox {
    width: 80%;
  }

  .login-area {
    margin: 0 auto 60px;
    margin-top: -90px;
  }

  .blog-thumbnail img {
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  .react-blog-slider .slick-arrow.slick-next {
    right: 0px;
  }

  .single-blog-section .banner-content .title span strong {
    display: block;
    width: 100%;
  }

  .single-blog-section {
    padding: 80px 0 0 0;
  }

  .single-blog-section .banner-content {
    margin: 0;
  }

  .mid-section {
    padding: 60px 0 0;
  }

  .react-blog-slider .blog-box {
    margin: 0 30px 0 0;
  }

  .commnet-box .sub-comment {
    padding: 0;
  }

  .commnet-box .author-content {
    width: 81%;
    padding: 15px 0 0 15px;
  }

  .commnet-box .author-box h4 strong {
    width: 30%;
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 501;
  }

  .commnet-box h4 .date {
    width: auto;
    padding: 0 0 0 20px;
    font-size: 14px;
  }

  .commnet-box h4 .date:before {
    left: 0;
  }
  .banner-slider .slick-prev, .banner-slider .slick-next {
    width: 40px;
    height: 40px;
    z-index: 999;
    top: 43px !important;
  }
  .blog-paragraph-post{
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 414px) {
  .live-device-screen {
    position: inherit;
    left: inherit;
    top: 75px;
    right: -10px;
    width: 70%;
    margin: 0 auto;
    background: none;
    background-size: 100%;
  }
  .shop-list .add-icon {
    top: 64%;
    left: 15%;
  }
  .women-fitness .watch-icon {
    top: 41%;
    left: 28%;
  }
  .banner-section-backgroung-img{
    width: 100%;
    display: block;
    padding: 0;
    margin: 100px auto 200px;
    background-image: url('../images/sketch-fold-1-bg.jpg');
    background-repeat: no-repeat;
    background-position: top-left;
    position: relative;
    background-size: 90%;
  }
  
}


/* Landscape Media Query*/
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {}

@media only screen and (min-device-width: 569px) and (max-device-width: 767px) and (orientation: landscape) {}

@media only screen and (min-device-width: 813px) and (max-device-width: 1279px) and (orientation: landscape) {}

@media only screen and (min-device-width: 569px) and (max-device-width: 812px) and (orientation: landscape) {}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {}

@media only screen and (min-width: 813px) and (max-width: 1080px) {}

@media only screen and (max-width: 767px) {

  /* .inner-page-banner .banner-content .title-register {
    line-height: 50px;
    margin: 100px 4px 20px 20px;
} */



  .banner-content .title-register {
    line-height: 50px;
    margin: 200px 0 20px 0;

  }

  .login-area .title-register {
    text-align: center;
  }

  .title-register {
    width: 100%;
    font-size: 45px;
    line-height: 42px;
    font-weight: 700;
    margin: 0 0 20px 0;
    padding: 0;
  }

  .contact-section .banner-content .title-register {
    margin: 50px 0 20px 0;
  }

  .title-register span {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #85b492;
    display: block;
    margin: 0 0 15px 0;
  }

  .inner-page-banner .banner-content .title-register {
    line-height: 50px;
    margin: 100px auto 20px;
    width: 80%;
  }

  p1 {
    margin: 0 0 20px 0;
    font-size: 15px;
  }

  .effect~label {
    position: absolute;
    left: 0;
    width: 100%;
    top: 5px;
    color: #85b492;
    transition: 0.3s;
    z-index: -1;
    font-size: 17px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  .ul-btn li span {
    display: inline-block;
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    line-height: 16px;
    margin: 0 0 0 10px;
    text-transform: uppercase;
    color: #000000;
  }

  .ul-btn li span strong {
    display: block;
    width: 100%;
    font-weight: 500;
    text-transform: initial;
    font-size: 12px;
    height: 25px;
  }


  .btn {
    min-width: 200px;
    font-size: 16px;
    line-height: 30px;
  }

  p {
    margin: 0 0 20px 0;
    font-size: 16px;
  }

  .contact-p {
    /* margin: 0 0 20px 0; */
    font-size: 17px;
    width: 100%;
    /* margin: 0 28px 30px; */
  }

  .blog-box .title {
    font-size: 20px;
    line-height: 25px;
    height: 10.4rem;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .lazyload-wrapper p {
    height: 9.3rem !important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .discover {
    padding-bottom: 2rem;
    height: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .logo img {
    width: 100%;
    display: block;
  }

  .p-comments {
    font-size: 15px;
    margin: 0 0 20px 0;
    font-weight: 410;
    color: black;
  }

  .b-comment {
    font-size: 27px;
    line-height: 24px;
    font-weight: 501;
  }

  .recent-posts {
    font-size: 30px;
  }

  .btn-register-header {
    width: auto;
    min-width: 160px !important;
    line-height: 20px;
  }

  .p-landing {
    /* width: 65%; */
    margin: 0 auto 30px;
    width: 80%;
  }

  .banner-section-landing-page {
    margin: 0 auto;
    /* margin-top: 40px; */
  }

  .recipes-slider-area .like-spe {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
  }

  .ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
    width: 30rem;
  }

  .allergy-section {
    padding: 80px 0;
    max-height: 933px;
  }
  .allergy-alert-recipes{
    width: 100%;
  }
  .allergy-alert-recipes-paragraph{
    width: 100%;
  }

  .recipes-slider .slick-arrow.slick-next {
    top: 45%;
    right: 0%;
  }
  .veg-box .speak-icon {
    top: 4%;
    right: 69%;
  }
}

@media only screen and (max-width: 991px) {
  .mob-width {
    max-width: 80%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1300px) {
  .testimonials-section:before {
    right: -5px;
    top: -275px;
  }
}

@media only screen and (max-width: 1050px) {
  .testimonials-section:before {
    right: -40px;
    top: -190px;
  }
}


@media only screen and (max-width: 991px) {
  .recipes-slider-area .gallery-icon {
    bottom: 10px;
    position: relative;
    top: 60rem;
  }


  .allergy-section {
    padding: 80px 0;
    max-height: 1100px;
  }

  .recipes-slider .slick-arrow.slick-next {
    top: 23%;
  }
}



@media only screen and (max-width: 767px) {
  .recipes-slider .slick-arrow.slick-next {
    top: 49%;
    right: 7%;
  }

  .recipes-slider-area .gallery-icon {
    bottom: 0;
    position: absolute;
    top: 300px;
    right: 50px;
  }

  .nav-links li a:hover {
    color: black;
  }
  .f-register{
    font-size: 13px !important;
  }
}