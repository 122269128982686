/* @import url('https://themes.googleusercontent.com/fonts/css?kit=cV31SLdlloJMxMaXzGDRsnp3AzkIGY9Chfk7P3KJeQY'); */

.lst-kix_1527032byqph-6>li:before {
    content: counter(lst-ctn-kix_1527032byqph-6, decimal)
}

ol.lst-kix_e4djldmek08t-8.start {
    counter-reset: lst-ctn-kix_e4djldmek08t-8 0
}

ol.lst-kix_rm2geegoz7xb-5.start {
    counter-reset: lst-ctn-kix_rm2geegoz7xb-5 0
}

.lst-kix_1527032byqph-3>li:before {
    content: counter(lst-ctn-kix_1527032byqph-3, decimal)
}

.lst-kix_1527032byqph-7>li:before {
    content: counter(lst-ctn-kix_1527032byqph-7, lower-latin)
}

.lst-kix_rqcqa5j9gmj3-7>li {
    counter-increment: lst-ctn-kix_rqcqa5j9gmj3-7
}

.lst-kix_rm2geegoz7xb-1>li:before {
    content: counter(lst-ctn-kix_rm2geegoz7xb-1, lower-latin)
}

.lst-kix_1527032byqph-2>li:before {
    content: counter(lst-ctn-kix_1527032byqph-2, lower-roman)
}

.lst-kix_hot193uo2wm5-2>li {
    counter-increment: lst-ctn-kix_hot193uo2wm5-2
}

.lst-kix_rm2geegoz7xb-2>li:before {
    content: counter(lst-ctn-kix_rm2geegoz7xb-2, lower-roman)
}

.lst-kix_1527032byqph-0>li:before {
    content: counter(lst-ctn-kix_1527032byqph-0, decimal)
}

.lst-kix_1527032byqph-8>li:before {
    content: counter(lst-ctn-kix_1527032byqph-8, lower-roman)
}

.lst-kix_rm2geegoz7xb-4>li {
    counter-increment: lst-ctn-kix_rm2geegoz7xb-4
}

.lst-kix_1527032byqph-1>li:before {
    content: counter(lst-ctn-kix_1527032byqph-1, lower-latin)
}

ol.lst-kix_zb050jb2dqhv-8.start {
    counter-reset: lst-ctn-kix_zb050jb2dqhv-8 0
}

.lst-kix_rm2geegoz7xb-3>li:before {
    content: counter(lst-ctn-kix_rm2geegoz7xb-3, decimal)
}

.lst-kix_1527032byqph-7>li {
    counter-increment: lst-ctn-kix_1527032byqph-7
}

.lst-kix_rm2geegoz7xb-0>li:before {
    content: counter(lst-ctn-kix_rm2geegoz7xb-0, decimal)
}

.lst-kix_e4djldmek08t-7>li:before {
    content: counter(lst-ctn-kix_e4djldmek08t-7, lower-latin)
}

.lst-kix_e4djldmek08t-8>li:before {
    content: counter(lst-ctn-kix_e4djldmek08t-8, lower-roman)
}

.lst-kix_hot193uo2wm5-0>li {
    counter-increment: lst-ctn-kix_hot193uo2wm5-0
}

.lst-kix_e4djldmek08t-5>li:before {
    content: counter(lst-ctn-kix_e4djldmek08t-5, lower-roman)
}

.lst-kix_e4djldmek08t-6>li:before {
    content: counter(lst-ctn-kix_e4djldmek08t-6, decimal)
}

.lst-kix_rm2geegoz7xb-2>li {
    counter-increment: lst-ctn-kix_rm2geegoz7xb-2
}

.lst-kix_zb050jb2dqhv-1>li {
    counter-increment: lst-ctn-kix_zb050jb2dqhv-1
}

.lst-kix_e4djldmek08t-0>li {
    counter-increment: lst-ctn-kix_e4djldmek08t-0
}

.lst-kix_e4djldmek08t-1>li:before {
    content: counter(lst-ctn-kix_e4djldmek08t-1, lower-latin)
}

.lst-kix_e4djldmek08t-2>li:before {
    content: counter(lst-ctn-kix_e4djldmek08t-2, lower-roman)
}

.lst-kix_rm2geegoz7xb-4>li:before {
    content: counter(lst-ctn-kix_rm2geegoz7xb-4, lower-latin)
}

.lst-kix_rm2geegoz7xb-6>li:before {
    content: counter(lst-ctn-kix_rm2geegoz7xb-6, decimal)
}

ol.lst-kix_1527032byqph-0.start {
    counter-reset: lst-ctn-kix_1527032byqph-0 0
}

ol.lst-kix_rqcqa5j9gmj3-1.start {
    counter-reset: lst-ctn-kix_rqcqa5j9gmj3-1 0
}

.lst-kix_e4djldmek08t-0>li:before {
    content: counter(lst-ctn-kix_e4djldmek08t-0, decimal)
}

.lst-kix_e4djldmek08t-3>li:before {
    content: counter(lst-ctn-kix_e4djldmek08t-3, decimal)
}

.lst-kix_e4djldmek08t-4>li:before {
    content: counter(lst-ctn-kix_e4djldmek08t-4, lower-latin)
}

ol.lst-kix_rm2geegoz7xb-8 {
    list-style-type: none
}

.lst-kix_rm2geegoz7xb-5>li:before {
    content: counter(lst-ctn-kix_rm2geegoz7xb-5, lower-roman)
}

.lst-kix_rm2geegoz7xb-8>li:before {
    content: counter(lst-ctn-kix_rm2geegoz7xb-8, lower-roman)
}

.lst-kix_1527032byqph-4>li:before {
    content: counter(lst-ctn-kix_1527032byqph-4, lower-latin)
}

.lst-kix_1527032byqph-5>li:before {
    content: counter(lst-ctn-kix_1527032byqph-5, lower-roman)
}

.lst-kix_rm2geegoz7xb-7>li:before {
    content: counter(lst-ctn-kix_rm2geegoz7xb-7, lower-latin)
}

.lst-kix_rqcqa5j9gmj3-7>li:before {
    content: counter(lst-ctn-kix_rqcqa5j9gmj3-7, lower-latin)
}

.lst-kix_e4djldmek08t-7>li {
    counter-increment: lst-ctn-kix_e4djldmek08t-7
}

.lst-kix_rqcqa5j9gmj3-6>li:before {
    content: counter(lst-ctn-kix_rqcqa5j9gmj3-6, decimal)
}

ol.lst-kix_rm2geegoz7xb-6 {
    list-style-type: none
}

ol.lst-kix_rm2geegoz7xb-7 {
    list-style-type: none
}

ol.lst-kix_rm2geegoz7xb-4 {
    list-style-type: none
}

ol.lst-kix_rm2geegoz7xb-5 {
    list-style-type: none
}

ol.lst-kix_rm2geegoz7xb-2 {
    list-style-type: none
}

ol.lst-kix_rm2geegoz7xb-3 {
    list-style-type: none
}

ol.lst-kix_rm2geegoz7xb-0 {
    list-style-type: none
}

.lst-kix_rqcqa5j9gmj3-0>li:before {
    content: counter(lst-ctn-kix_rqcqa5j9gmj3-0, decimal)
}

.lst-kix_rqcqa5j9gmj3-8>li:before {
    content: counter(lst-ctn-kix_rqcqa5j9gmj3-8, lower-roman)
}

ol.lst-kix_rm2geegoz7xb-1 {
    list-style-type: none
}

.lst-kix_rqcqa5j9gmj3-1>li:before {
    content: counter(lst-ctn-kix_rqcqa5j9gmj3-1, lower-latin)
}

.lst-kix_rqcqa5j9gmj3-2>li:before {
    content: counter(lst-ctn-kix_rqcqa5j9gmj3-2, lower-roman)
}

.lst-kix_rqcqa5j9gmj3-3>li:before {
    content: counter(lst-ctn-kix_rqcqa5j9gmj3-3, decimal)
}

.lst-kix_rqcqa5j9gmj3-5>li:before {
    content: counter(lst-ctn-kix_rqcqa5j9gmj3-5, lower-roman)
}

.lst-kix_zb050jb2dqhv-5>li {
    counter-increment: lst-ctn-kix_zb050jb2dqhv-5
}

.lst-kix_rm2geegoz7xb-8>li {
    counter-increment: lst-ctn-kix_rm2geegoz7xb-8
}

.lst-kix_rqcqa5j9gmj3-4>li:before {
    content: counter(lst-ctn-kix_rqcqa5j9gmj3-4, lower-latin)
}

.lst-kix_hot193uo2wm5-4>li {
    counter-increment: lst-ctn-kix_hot193uo2wm5-4
}

ol.lst-kix_rqcqa5j9gmj3-5.start {
    counter-reset: lst-ctn-kix_rqcqa5j9gmj3-5 0
}

.lst-kix_1527032byqph-2>li {
    counter-increment: lst-ctn-kix_1527032byqph-2
}

.lst-kix_zb050jb2dqhv-8>li {
    counter-increment: lst-ctn-kix_zb050jb2dqhv-8
}

ol.lst-kix_1527032byqph-1.start {
    counter-reset: lst-ctn-kix_1527032byqph-1 0
}

ol.lst-kix_rqcqa5j9gmj3-8.start {
    counter-reset: lst-ctn-kix_rqcqa5j9gmj3-8 0
}

ol.lst-kix_e4djldmek08t-6 {
    list-style-type: none
}

ol.lst-kix_e4djldmek08t-5 {
    list-style-type: none
}

ol.lst-kix_e4djldmek08t-8 {
    list-style-type: none
}

ol.lst-kix_e4djldmek08t-7 {
    list-style-type: none
}

ol.lst-kix_e4djldmek08t-2 {
    list-style-type: none
}

ol.lst-kix_e4djldmek08t-1 {
    list-style-type: none
}

ol.lst-kix_e4djldmek08t-4 {
    list-style-type: none
}

ol.lst-kix_e4djldmek08t-3 {
    list-style-type: none
}

ol.lst-kix_hot193uo2wm5-3.start {
    counter-reset: lst-ctn-kix_hot193uo2wm5-3 0
}

.lst-kix_1527032byqph-1>li {
    counter-increment: lst-ctn-kix_1527032byqph-1
}

.lst-kix_zb050jb2dqhv-1>li:before {
    content: counter(lst-ctn-kix_zb050jb2dqhv-1, lower-latin)
}

.lst-kix_zb050jb2dqhv-3>li:before {
    content: counter(lst-ctn-kix_zb050jb2dqhv-3, decimal)
}

ol.lst-kix_zb050jb2dqhv-7.start {
    counter-reset: lst-ctn-kix_zb050jb2dqhv-7 0
}

ol.lst-kix_hot193uo2wm5-0 {
    list-style-type: none
}

ol.lst-kix_hot193uo2wm5-1 {
    list-style-type: none
}

ol.lst-kix_hot193uo2wm5-6.start {
    counter-reset: lst-ctn-kix_hot193uo2wm5-6 0
}

.lst-kix_hot193uo2wm5-0>li:before {
    content: counter(lst-ctn-kix_hot193uo2wm5-0, decimal)
}

.lst-kix_zb050jb2dqhv-5>li:before {
    content: counter(lst-ctn-kix_zb050jb2dqhv-5, lower-roman)
}

.lst-kix_e4djldmek08t-3>li {
    counter-increment: lst-ctn-kix_e4djldmek08t-3
}

ol.lst-kix_e4djldmek08t-7.start {
    counter-reset: lst-ctn-kix_e4djldmek08t-7 0
}

ol.lst-kix_rm2geegoz7xb-7.start {
    counter-reset: lst-ctn-kix_rm2geegoz7xb-7 0
}

.lst-kix_hot193uo2wm5-8>li {
    counter-increment: lst-ctn-kix_hot193uo2wm5-8
}

.lst-kix_1527032byqph-3>li {
    counter-increment: lst-ctn-kix_1527032byqph-3
}

.lst-kix_rqcqa5j9gmj3-0>li {
    counter-increment: lst-ctn-kix_rqcqa5j9gmj3-0
}

.lst-kix_zb050jb2dqhv-7>li:before {
    content: counter(lst-ctn-kix_zb050jb2dqhv-7, lower-latin)
}

ol.lst-kix_rm2geegoz7xb-4.start {
    counter-reset: lst-ctn-kix_rm2geegoz7xb-4 0
}

.lst-kix_hot193uo2wm5-6>li:before {
    content: counter(lst-ctn-kix_hot193uo2wm5-6, decimal)
}

.lst-kix_hot193uo2wm5-8>li:before {
    content: counter(lst-ctn-kix_hot193uo2wm5-8, lower-roman)
}

ol.lst-kix_rqcqa5j9gmj3-7.start {
    counter-reset: lst-ctn-kix_rqcqa5j9gmj3-7 0
}

ol.lst-kix_hot193uo2wm5-5.start {
    counter-reset: lst-ctn-kix_hot193uo2wm5-5 0
}

.lst-kix_hot193uo2wm5-2>li:before {
    content: counter(lst-ctn-kix_hot193uo2wm5-2, lower-roman)
}

.lst-kix_hot193uo2wm5-4>li:before {
    content: counter(lst-ctn-kix_hot193uo2wm5-4, lower-latin)
}

.lst-kix_e4djldmek08t-2>li {
    counter-increment: lst-ctn-kix_e4djldmek08t-2
}

.lst-kix_rqcqa5j9gmj3-5>li {
    counter-increment: lst-ctn-kix_rqcqa5j9gmj3-5
}

ol.lst-kix_hot193uo2wm5-4.start {
    counter-reset: lst-ctn-kix_hot193uo2wm5-4 0
}

ol.lst-kix_rqcqa5j9gmj3-0 {
    list-style-type: none
}

ol.lst-kix_rqcqa5j9gmj3-1 {
    list-style-type: none
}

ol.lst-kix_rm2geegoz7xb-6.start {
    counter-reset: lst-ctn-kix_rm2geegoz7xb-6 0
}

ol.lst-kix_rqcqa5j9gmj3-2 {
    list-style-type: none
}

ol.lst-kix_rqcqa5j9gmj3-6.start {
    counter-reset: lst-ctn-kix_rqcqa5j9gmj3-6 0
}

ol.lst-kix_e4djldmek08t-0 {
    list-style-type: none
}

ol.lst-kix_rqcqa5j9gmj3-3 {
    list-style-type: none
}

ol.lst-kix_rqcqa5j9gmj3-4 {
    list-style-type: none
}

ol.lst-kix_rqcqa5j9gmj3-5 {
    list-style-type: none
}

ol.lst-kix_rqcqa5j9gmj3-6 {
    list-style-type: none
}

ol.lst-kix_rqcqa5j9gmj3-7 {
    list-style-type: none
}

.lst-kix_rm2geegoz7xb-3>li {
    counter-increment: lst-ctn-kix_rm2geegoz7xb-3
}

.lst-kix_zb050jb2dqhv-3>li {
    counter-increment: lst-ctn-kix_zb050jb2dqhv-3
}

.lst-kix_1527032byqph-8>li {
    counter-increment: lst-ctn-kix_1527032byqph-8
}

.lst-kix_zb050jb2dqhv-2>li {
    counter-increment: lst-ctn-kix_zb050jb2dqhv-2
}

ol.lst-kix_rqcqa5j9gmj3-8 {
    list-style-type: none
}

ol.lst-kix_zb050jb2dqhv-5.start {
    counter-reset: lst-ctn-kix_zb050jb2dqhv-5 0
}

ol.lst-kix_rm2geegoz7xb-8.start {
    counter-reset: lst-ctn-kix_rm2geegoz7xb-8 0
}

.lst-kix_rqcqa5j9gmj3-8>li {
    counter-increment: lst-ctn-kix_rqcqa5j9gmj3-8
}

.lst-kix_hot193uo2wm5-3>li {
    counter-increment: lst-ctn-kix_hot193uo2wm5-3
}

.lst-kix_rm2geegoz7xb-5>li {
    counter-increment: lst-ctn-kix_rm2geegoz7xb-5
}

.lst-kix_zb050jb2dqhv-4>li {
    counter-increment: lst-ctn-kix_zb050jb2dqhv-4
}

.lst-kix_rqcqa5j9gmj3-6>li {
    counter-increment: lst-ctn-kix_rqcqa5j9gmj3-6
}

.lst-kix_e4djldmek08t-8>li {
    counter-increment: lst-ctn-kix_e4djldmek08t-8
}

ol.lst-kix_hot193uo2wm5-2.start {
    counter-reset: lst-ctn-kix_hot193uo2wm5-2 0
}

.lst-kix_1527032byqph-6>li {
    counter-increment: lst-ctn-kix_1527032byqph-6
}

ol.lst-kix_e4djldmek08t-0.start {
    counter-reset: lst-ctn-kix_e4djldmek08t-0 0
}

ol.lst-kix_rqcqa5j9gmj3-4.start {
    counter-reset: lst-ctn-kix_rqcqa5j9gmj3-4 0
}

ol.lst-kix_1527032byqph-3.start {
    counter-reset: lst-ctn-kix_1527032byqph-3 0
}

.lst-kix_e4djldmek08t-1>li {
    counter-increment: lst-ctn-kix_e4djldmek08t-1
}

.lst-kix_rqcqa5j9gmj3-4>li {
    counter-increment: lst-ctn-kix_rqcqa5j9gmj3-4
}

.lst-kix_rm2geegoz7xb-1>li {
    counter-increment: lst-ctn-kix_rm2geegoz7xb-1
}

ol.lst-kix_rm2geegoz7xb-3.start {
    counter-reset: lst-ctn-kix_rm2geegoz7xb-3 0
}

.lst-kix_rm2geegoz7xb-7>li {
    counter-increment: lst-ctn-kix_rm2geegoz7xb-7
}

ol.lst-kix_rqcqa5j9gmj3-3.start {
    counter-reset: lst-ctn-kix_rqcqa5j9gmj3-3 0
}

ol.lst-kix_hot193uo2wm5-1.start {
    counter-reset: lst-ctn-kix_hot193uo2wm5-1 0
}

ol.lst-kix_hot193uo2wm5-8.start {
    counter-reset: lst-ctn-kix_hot193uo2wm5-8 0
}

ol.lst-kix_e4djldmek08t-6.start {
    counter-reset: lst-ctn-kix_e4djldmek08t-6 0
}

ol.lst-kix_hot193uo2wm5-2 {
    list-style-type: none
}

ol.lst-kix_hot193uo2wm5-3 {
    list-style-type: none
}

ol.lst-kix_1527032byqph-7.start {
    counter-reset: lst-ctn-kix_1527032byqph-7 0
}

ol.lst-kix_hot193uo2wm5-4 {
    list-style-type: none
}

ol.lst-kix_hot193uo2wm5-5 {
    list-style-type: none
}

ol.lst-kix_hot193uo2wm5-6 {
    list-style-type: none
}

ol.lst-kix_hot193uo2wm5-7 {
    list-style-type: none
}

.lst-kix_1527032byqph-4>li {
    counter-increment: lst-ctn-kix_1527032byqph-4
}

ol.lst-kix_hot193uo2wm5-8 {
    list-style-type: none
}

ol.lst-kix_1527032byqph-2.start {
    counter-reset: lst-ctn-kix_1527032byqph-2 0
}

ol.lst-kix_zb050jb2dqhv-0.start {
    counter-reset: lst-ctn-kix_zb050jb2dqhv-0 0
}

ol.lst-kix_rm2geegoz7xb-2.start {
    counter-reset: lst-ctn-kix_rm2geegoz7xb-2 0
}

ol.lst-kix_e4djldmek08t-5.start {
    counter-reset: lst-ctn-kix_e4djldmek08t-5 0
}

.lst-kix_hot193uo2wm5-1>li {
    counter-increment: lst-ctn-kix_hot193uo2wm5-1
}

ol.lst-kix_zb050jb2dqhv-6.start {
    counter-reset: lst-ctn-kix_zb050jb2dqhv-6 0
}

ol.lst-kix_1527032byqph-8.start {
    counter-reset: lst-ctn-kix_1527032byqph-8 0
}

.lst-kix_e4djldmek08t-4>li {
    counter-increment: lst-ctn-kix_e4djldmek08t-4
}

ol.lst-kix_hot193uo2wm5-7.start {
    counter-reset: lst-ctn-kix_hot193uo2wm5-7 0
}

ol.lst-kix_zb050jb2dqhv-6 {
    list-style-type: none
}

ol.lst-kix_e4djldmek08t-1.start {
    counter-reset: lst-ctn-kix_e4djldmek08t-1 0
}

ol.lst-kix_zb050jb2dqhv-5 {
    list-style-type: none
}

ol.lst-kix_zb050jb2dqhv-8 {
    list-style-type: none
}

ol.lst-kix_zb050jb2dqhv-7 {
    list-style-type: none
}

ol.lst-kix_hot193uo2wm5-0.start {
    counter-reset: lst-ctn-kix_hot193uo2wm5-0 0
}

.lst-kix_hot193uo2wm5-7>li {
    counter-increment: lst-ctn-kix_hot193uo2wm5-7
}

.lst-kix_rqcqa5j9gmj3-1>li {
    counter-increment: lst-ctn-kix_rqcqa5j9gmj3-1
}

ol.lst-kix_rqcqa5j9gmj3-2.start {
    counter-reset: lst-ctn-kix_rqcqa5j9gmj3-2 0
}

.lst-kix_rqcqa5j9gmj3-2>li {
    counter-increment: lst-ctn-kix_rqcqa5j9gmj3-2
}

ol.lst-kix_e4djldmek08t-4.start {
    counter-reset: lst-ctn-kix_e4djldmek08t-4 0
}

ol.lst-kix_zb050jb2dqhv-2 {
    list-style-type: none
}

ol.lst-kix_zb050jb2dqhv-1 {
    list-style-type: none
}

ol.lst-kix_zb050jb2dqhv-4 {
    list-style-type: none
}

ol.lst-kix_1527032byqph-4.start {
    counter-reset: lst-ctn-kix_1527032byqph-4 0
}

ol.lst-kix_zb050jb2dqhv-3 {
    list-style-type: none
}

.lst-kix_zb050jb2dqhv-0>li:before {
    content: counter(lst-ctn-kix_zb050jb2dqhv-0, decimal)
}

ol.lst-kix_zb050jb2dqhv-0 {
    list-style-type: none
}

.lst-kix_zb050jb2dqhv-2>li:before {
    content: counter(lst-ctn-kix_zb050jb2dqhv-2, lower-roman)
}

.lst-kix_zb050jb2dqhv-6>li:before {
    content: counter(lst-ctn-kix_zb050jb2dqhv-6, decimal)
}

.lst-kix_e4djldmek08t-5>li {
    counter-increment: lst-ctn-kix_e4djldmek08t-5
}

.lst-kix_zb050jb2dqhv-7>li {
    counter-increment: lst-ctn-kix_zb050jb2dqhv-7
}

.lst-kix_1527032byqph-0>li {
    counter-increment: lst-ctn-kix_1527032byqph-0
}

.lst-kix_zb050jb2dqhv-4>li:before {
    content: counter(lst-ctn-kix_zb050jb2dqhv-4, lower-latin)
}

ol.lst-kix_zb050jb2dqhv-4.start {
    counter-reset: lst-ctn-kix_zb050jb2dqhv-4 0
}

ol.lst-kix_1527032byqph-2 {
    list-style-type: none
}

.lst-kix_hot193uo2wm5-5>li {
    counter-increment: lst-ctn-kix_hot193uo2wm5-5
}

ol.lst-kix_1527032byqph-1 {
    list-style-type: none
}

ol.lst-kix_1527032byqph-0 {
    list-style-type: none
}

ol.lst-kix_rm2geegoz7xb-1.start {
    counter-reset: lst-ctn-kix_rm2geegoz7xb-1 0
}

.lst-kix_rqcqa5j9gmj3-3>li {
    counter-increment: lst-ctn-kix_rqcqa5j9gmj3-3
}

ol.lst-kix_1527032byqph-6 {
    list-style-type: none
}

ol.lst-kix_1527032byqph-5 {
    list-style-type: none
}

ol.lst-kix_1527032byqph-4 {
    list-style-type: none
}

ol.lst-kix_1527032byqph-3 {
    list-style-type: none
}

ol.lst-kix_zb050jb2dqhv-1.start {
    counter-reset: lst-ctn-kix_zb050jb2dqhv-1 0
}

ol.lst-kix_1527032byqph-8 {
    list-style-type: none
}

.lst-kix_zb050jb2dqhv-8>li:before {
    content: counter(lst-ctn-kix_zb050jb2dqhv-8, lower-roman)
}

ol.lst-kix_1527032byqph-7 {
    list-style-type: none
}

.lst-kix_1527032byqph-5>li {
    counter-increment: lst-ctn-kix_1527032byqph-5
}

.lst-kix_hot193uo2wm5-7>li:before {
    content: counter(lst-ctn-kix_hot193uo2wm5-7, lower-latin)
}

ol.lst-kix_zb050jb2dqhv-3.start {
    counter-reset: lst-ctn-kix_zb050jb2dqhv-3 0
}

ol.lst-kix_e4djldmek08t-3.start {
    counter-reset: lst-ctn-kix_e4djldmek08t-3 0
}

ol.lst-kix_1527032byqph-6.start {
    counter-reset: lst-ctn-kix_1527032byqph-6 0
}

.lst-kix_hot193uo2wm5-3>li:before {
    content: counter(lst-ctn-kix_hot193uo2wm5-3, decimal)
}

.lst-kix_hot193uo2wm5-1>li:before {
    content: counter(lst-ctn-kix_hot193uo2wm5-1, lower-latin)
}

.lst-kix_hot193uo2wm5-5>li:before {
    content: counter(lst-ctn-kix_hot193uo2wm5-5, lower-roman)
}

.lst-kix_e4djldmek08t-6>li {
    counter-increment: lst-ctn-kix_e4djldmek08t-6
}

ol.lst-kix_rm2geegoz7xb-0.start {
    counter-reset: lst-ctn-kix_rm2geegoz7xb-0 0
}

ol.lst-kix_rqcqa5j9gmj3-0.start {
    counter-reset: lst-ctn-kix_rqcqa5j9gmj3-0 0
}

.lst-kix_hot193uo2wm5-6>li {
    counter-increment: lst-ctn-kix_hot193uo2wm5-6
}

ol.lst-kix_e4djldmek08t-2.start {
    counter-reset: lst-ctn-kix_e4djldmek08t-2 0
}

ol.lst-kix_zb050jb2dqhv-2.start {
    counter-reset: lst-ctn-kix_zb050jb2dqhv-2 0
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
}


.lst-kix_zb050jb2dqhv-0>li {
    counter-increment: lst-ctn-kix_zb050jb2dqhv-0
}

.lst-kix_rm2geegoz7xb-6>li {
    counter-increment: lst-ctn-kix_rm2geegoz7xb-6
}

ol.lst-kix_1527032byqph-5.start {
    counter-reset: lst-ctn-kix_1527032byqph-5 0
}

.lst-kix_rm2geegoz7xb-0>li {
    counter-increment: lst-ctn-kix_rm2geegoz7xb-0
}

.lst-kix_zb050jb2dqhv-6>li {
    counter-increment: lst-ctn-kix_zb050jb2dqhv-6
}

ol {
    margin: 0;
    padding: 0;
}

table td,
table th {
    padding: 0;
}

.c3 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.6730769230769231;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt;
}

.c13 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.4558823529411764;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt;
}

.c4 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.2;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
}

.c10 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-bottom: 0pt;
    line-height: 1.3846153846153846;
    border-top-style: solid;
    margin-left: 36pt;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt;
    height: 11pt
}

.c1 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.3846153846153846;
    border-top-style: solid;
    margin-left: 36pt;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt;
    color: #707070;
    font-size: 12pt;
}

.c11 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    font-size: 12pt;
    color: #707070;
}

.c6 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.c0 {
    color: #707070;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "poppins";
    font-style: normal;
    line-height: 32px;
}

.c5 {
    color: #000000;
    font-weight: 600;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-family: "poppins";
    font-style: normal;
    color: #85B492;
    line-height: 45px;
}

.c9 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c7 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Assistant";
    font-style: normal
}

.c15 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal
}

.c2 {
    font-size: 12pt;
    font-family: poppins;
    /* font-weight: 600; */
    color: #707070;
    line-height: 29px;
}

.c14 {
    font-size: 22.5pt;
    font-family: "Assistant";
    font-weight: 700;
    font-family: inherit !important;
    color: #85B492;
}

.c12 {
    background-color: #ffffff;
    max-width: 451.3pt;
    padding: 72pt 72pt 72pt 72pt
}

.c8 {
    padding: 0;
    margin: 0
}

.c0-1 {
    color: #707070;
    ;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 68px;

}

.c0-2 {
    color: #707070;
    font-weight: bold;
}

.c2-1 {
    font-size: 12pt;
    font-weight: 400
}

.c2-01 {
    font-size: 14pt;
    font-weight: 600;
}

.c0-personal-info {
    font-size: 14pt;
    font-weight: 600;
    line-height: 46px;
}

.c14-01 {
    font-size: 22.5pt;
    /* font-family: "Assistant"; */
    font-weight: 700;
    font-family: inherit !important;
    color: #85B492;
}

.c3-01 {
    color: #707070;
    font-size: 15pt;
    font-weight: 600;
    font-family: poppins;
}

.c3-02 {
    line-height: 66px;
}

.c14-02 {
    color: #707070;
    font-family: poppins !important;
    font-size: 12pt;
    font-weight: 400;
    line-height: 29px;
}

.c3-03 {
    line-height: 59px;
}

.imp-note {
    font-weight: 600;
}


/* .privacy-title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
} */

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
    /* display: none */
}

/* p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
} */

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

/* h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
} */

/* h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
} */

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}