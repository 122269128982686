body {
  font-family: poppins;
}

html,
body {
  margin: 0;
  padding: 0%;
  overflow-x: hidden;
}

html {
  height: 100%;
}

.create-recipe-page-1 {
  background: url(../images/sketch\ 1\ \(1\).jpg) no-repeat 100% 0;
  background-size: contain;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  padding: 85px 0 80px;
  position: relative;
  width: 100%;
}

.create-recipe-title {
  color: #85B492;
  font-weight: 500;
  font-family: poppins;
}

.create-recipe-heading {
  color: #000000;
  font-family: poppins;
  font-size: calc(2.5rem + 2vw);
  font-weight: 700;
}

.create-recipe-paragraph-section-1 {
  font-family: poppins;
  color: #616161;
  font-size: calc(0.9rem + .25vw);
}

.progressbar {}

.progressbar li {
  list-style: none;
  float: left;
  width: 25%;
  font-family: poppins;
}

.recipe-section-1 {
  font-family: poppins;
}

.recipe-name {
  font-family: poppins;
  font-size: 18px;
  font-weight: 600;
  color: #05172C;
  margin: 0;
}

.recipes-examples {
  color: #A4A4A4;
  font-size: 16px;
}

.enter-your-recipe-name-section {
  border-radius: 3px;
}

.effect-1 {
  width: 100%;
  border: 0;
  padding: 4px 0;
  border-bottom: 1px solid #ECECEC;
  background-color: transparent;
  resize: none;
  outline: none;
  font-size: 14px;
}

.input-effect-create-recips {
  display: block;
  width: 100%;
  position: relative;
  top: 0.5rem;
}

.video-upload-section {
  padding: 7rem;
  border: 2px dotted #C4C4C4;
  border-radius: 20px;

}

.upload-video {
  color: #05172C;
  font-size: 16px;
  font-weight: 500;
}

.difficulty-button {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #FFF;
  color: #05172C;
  border-radius: 24px !important;
  border: 1px solid #C4C4C4 !important;
  line-height: 30px;

}

.difficulty-button:hover {
  background: #85b492;
  /* border: 2px solid #85b492; */
  /* color: #fff */
}

.next-button {
  background-color: #85B492 !important;
  color: white !important;
  padding-left: 5rem !important;
  padding-right: 5rem !important;
  border: none !important;
  border-radius: 24px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #FFF !important;
  font-family: poppins !important;
}

.red-star {
  color: #C30202;
}

.no-of-images {
  font-weight: normal;
}

.steps-bg-color {
  background-color: #263238 !important;
}

.selection-suggestion {
  font-size: 14px;
  color: #A4A4A4;
}

.effect-1~.focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #85b492;
  transition: 0.4s;
}

.effect-1:focus~.focus-border,
.has-content.effect-1~.focus-border {
  width: 100%;
  transition: 0.4s;
}

.effect-1~label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 5px;
  color: #A4A4A4;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
  text-align: left;
  font-size: 14px;
  font-family: poppins;
}

.effect-1:focus~label,
.has-content.effect-1~label {
  top: -20px;
  font-size: 4;
  line-height: 30px;
  transition: 0.3s;
}

/* .step-2-input-fields{
    margin: 1rem 0;
  } */
.add-ingredient-box {
  padding: 5rem;
  border: 1px solid #ECECEC;
  border-radius: 21px;
  /* color: #ECECEC; */
  padding-left: 3rem;
padding-right: 3rem;
}

.previous-btn {
  color: #85B492;
  background-color: #FFF;
  border: 1px solid #85B492;
  padding-left: 5rem;
  padding-right: 5rem;
  border-radius: 25px;
  font-family: poppins;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
}

.hr-color {
  color: #ECECEC;
  margin-top: 7rem;
}

.cuisine-btn {
  color: #05172C;
  background-color: #FFF;
  border: 1px solid #85B492;
  border: 1px solid #ECECEC;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 25px;
  font-family: poppins;
  font-size: 14px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  line-height: 30px;
  margin-right: 1rem;
}

.step-2-input-field {
  color: #A4A4A4;
  font-family: poppins;
  font-weight: 400;
  font-size: 14px;
}

.background-img-step-5 {
  background-image: url(../images/Rectangle\ 589.png);
  background-size: contain;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  padding: 85px 0 80px;
  position: relative;
  width: 100%;
  border-radius: 10px;
  background-repeat: no-repeat;

}

.img-text-top-right {
  position: absolute;
  top: 7px;
  right: 36px;
  color: #FFF;
  font-family: poppins;
  font-size: 18px;
  text-shadow: gray 2px 2px 10px;
}

.img-text {
  position: absolute;
  top: 649px;
  right: 640px;
  color: #FFF;
  font-family: poppins;
  font-size: 18px;
  text-shadow: gray 2px 2px 10px;
  font-weight: 600;
}

.edit-btn {
  position: absolute;
  top: 262px;
  right: 125px;
  color: #FFF;
}

.dish-type {
  font-family: poppins;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: #85B492;
}

.edit-icon-position {
  position: absolute;
  right: 12px;
}

.prep-btn {
  color: #85B492;
  background-color: #FFF;
  border: 1px solid #85B492;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 25px;
  font-family: poppins;
  font-size: 14px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  line-height: 30px;
}

.prep-text {
  font-family: poppins;
  color: #05172C;
  font-size: 14px;
  font-weight: 500;
}

.edit-icon-position-fold-1 {
  position: absolute;
  right: 136px;
  top: 280px;
}
.responsive-img{
  height: 400px;
  border-radius: 10px;
  
}
.play-video{
  position: absolute;
}
.video-play{
  height: 300px;
  width: 350px;
}
.create-recipe-step-5-box{
  margin-top: 3rem;
margin-bottom: 3rem;
padding: 15px;
box-shadow: 2px 2px 10px -6px rgba(0,0,0,0.75);
border-radius: 10px;
}

.circular-slider{
  display: flex;
  justify-content: space-evenly;
}

/* 20220909 uploaded image  */
.upload-image-container{
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  margin-top: 5px;
  margin-top: 15px;
}

.upload-image-container .image-container{
  width:100px;
  height: 100px;
  position: relative;
  /* width:100px; */
  /* height: 100px; */

}

.upload-image-container .upload-image{
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.upload-image-container label{
  width: 100px;
}

.image-delete{
  position: absolute;
  right: -6px;
  top: -25px;
  z-index: 100;
  font-weight: bolder;
  height: 30px;
  width: 30px;
  font-size: 20px;
  /* background-color: white; */
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  /* border: 3px solid red; */
}

.image-delete-video{
  right: 12px;
  top: -32px;
  font-size: 25px;
}

.video-input{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.VideoInput{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

}
.video-upload-section{
  padding: 0;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* 20220914 adding image from api to ingredients list */
.modal-ing-img{
  height: 50px;
  width: auto;
  transition: transform .3s ease;
}

.modal-ing-img:hover{
  transform: scale(1.30);
}
.ingredient-name{
  margin: 0;
  flex: 1;
  /* flex: 1 !important; */
}
[data-icon="square-check"] ~ .modal-ing-img{
  transform: scale(1.20);
}

.ingredient-search-field{
  margin-top: 50px;
  border-radius: 20px;
  border-color: #85B492;
  border: solid;
  outline: none;
}

#ingredient-search{
  padding: 5px 10px;
}
.ingredient-name-in-list{
  flex: 1;
}

button.slick-arrow{
  display: block;
    background: #85B492;
    color: yellow;
    border-radius: 50%;
}

.search-recipe-icon{
  position: absolute;
  right: 10px;
  top: 0px;
}
.thumbs-up{
  display: flex;
padding: 1rem;
border-radius: 43px;
background: white;
}

.heart-create-recipe-thumbnail-bg{
display: flex;
padding: 1rem;
border-radius: 50px;
background: white;
height: 50px;
width: 50px;
}

.ingredient-quantity{
  width: 100% !important;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #85b492;
  margin-right: 18px;
  text-align: center;
}
.add-ingredient-dropdown{
background-color: #fff;
border-bottom: 1px solid #85b492 !important;
border: none;
text-align: left;
line-height: 1;
cursor: pointer;
padding: 8px;
transition: background-color 0.2s ease;

}
.add-ingredient-dropdown option {
  color: #707070;
}
.dropdown-menu-ingredient:hover{
  background-color: yellow !important;
}

.add-directions-step-3{
  font-family: poppins;
  color: #707070;
}
@media (max-width: 575.98px) {
  .next-button {
    background-color: #85B492 !important;
    color: white !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    border: none !important;
    border-radius: 24px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #FFF !important;
    font-family: poppins !important;
  }

  .previous-btn {
    color: #85B492 !important;
    background-color: #FFF !important;
    border: 1px solid #85B492 !important;
    border: 1px solid #85B492 !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    border-radius: 25px !important;
    font-family: poppins !important;
    font-size: 14px;
  }

  .preview-before-btn {
    background-color: #85B492 !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    border: none !important;
    border-radius: 24px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #FFF !important;
    font-family: poppins !important;
    margin-top: 1rem;
  }

  .edit-icon-position-fold-1 {
    position: absolute;
    right: 33px;
    top: 268px;
  }

  .img-text-top-right {
    position: absolute;
    top: 2px;
    right: 52px;
    color: #fff;
    font-family: poppins;
    font-size: 11px;
  }
  .video-play {
    height: 300px;
    width: 314px;
  }
  .responsive-img {
    height: 250px;
  }
  .add-directions-step-3{
    font-family: poppins;
    color: #707070;
    width: 70% !important;
  }
  .forgotPasswordModal {
    width: 325px !important;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
      padding-top: 20px;
  }
  .ingredient-modal {
    min-height: 500px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    z-index: 1000;
    width: 342px !important;
  }
  

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .img-text-top-right {
    position: absolute;
    top: 7px;
    right: 79px;
    color: #FFF;
    font-family: poppins;
    font-size: 18px;
  }

  .edit-icon-position-fold-1 {
    position: absolute;
    right: 77px;
    top: 265px;
  }

  .img-text {
    position: absolute;
    top: 381px;
    right: 335px;
    color: #FFF;
    font-family: poppins;
    font-size: 18px;
  }
  .forgotPasswordModal {
    width: 320px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
      padding-top: 20px;
  }
  
}
}